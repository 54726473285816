import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../context/AuthContext/AuthContext';
import { useTab } from '../../../context/TabContext/TabContext';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { downloadKycDocument } from '../../../services/admin/kyc/kyc';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  accountInformations,
  deleteAffiliationCommission,
  switchPaymentAffiliate,
  downloadIbanDocument,
  getAllActiveChallenges,
  getAllAffiliationsCommissions,
  getAllTransactions,
  getCryptoAddress,
  getIbanDocuments,
  getKycDocuments,
  giveChallengeToUser,
  modifyPassword,
  updateUserWallet,
  switchAffiliationToRank,
  switchAffiliationToPercentage,
  changeAffiliationDisplayName,
  changeUserReferrer,
  resentCredentials,
  createVariant,
  switchFundedToGroup,
  updateUserEmail,
  getAllActiveFunded,
  fetchAffiliateCommissionsPayoutRequest,
  fetchAffiliatePayoutRequest,
  deleteKycDocument,
  mergeAccounts,
  deleteFundedChallenge,
  deleteChallenge,
  getFundedByReferrerUUID,
  switchAccount,
  getGroupsByReferrer,
  setDefaultGroup,
  createNewBooksVariant,
} from '../../../services/admin/userAbout/userAbout';
import {
  personalInformation,
  accountInformation,
  financialInformation,
  TransactionContent,
  AffiliationsCommissionsContent,
  activeChallengesResponse,
  activeFundedResponse,
  BookSwitchRequest,
} from '../../../services/admin/userAbout/interface';
import { logout } from '../../../services/auth/login';

import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';

import Modal from '../../../components/Modal/Modal';
import Loader from '../../../components/Loader/Loader';
import Tab from '../../../components/Tab/Tab';
import Button from '../../../components/userInterface/Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import Table from '../../../components/Table/Table';
import UserProfileTable from '../UserProfileTable/UserProfileTable';
import Pagination from '../../../components/Pagination/Pagination';
import Select from '../../../components/userInterface/Select/Select';
import Error from '../../../components/Error/Error';
import Input from '../../../components/userInterface/Input/Input';
import Icon from '../../../components/userInterface/Icon/Icon';
import PayoutDropdown from '../../../components/PayoutDropdown/PayoutDropdown';
import Tooltip from '../../../components/userInterface/Tooltip/Tooltip';
import MuiTooltip from '@mui/material/Tooltip';
import ButtonMUI from '@mui/material/Button';
import MUIModal from '@mui/material/Modal';

import classes from './_userProfile.module.scss';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Card,
  CardHeader,
  Checkbox,
  DialogContentText,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';

interface UserProfileProps {
  isOpen: boolean;
  onClose: () => void;
  uuid: string;
}

interface PaginationItem {
  index: number;
  currentPage: number;
}

function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly string[], b: readonly string[]) {
  return [...a, ...not(b, a)];
}

const UserProfile: React.FC<UserProfileProps> = ({ isOpen, onClose, uuid }) => {
  const { isDarkMode } = useThemeContext();
  const { t } = useTranslation(['partnerArea', 'components', 'common']);
  const { tokens, isTokensLoading } = useAuth();
  const { setActiveTab } = useTab();

  const [checked, setChecked] = useState<readonly string[]>([]);
  const [bBook, setBBook] = useState<readonly string[]>([
    'Salut',
    'Hello',
    'Hi',
    'Hola',
  ]);
  const [aBook, setABook] = useState<readonly string[]>([
    'Glue',
    'Scotch',
    'Colle',
    'Low',
  ]);

  const leftChecked = intersection(checked, bBook);
  const rightChecked = intersection(checked, aBook);

  const [groupSelected, setGroupSelected] = useState<string | undefined>();
  const [userAdminId, setUserAdminId] = useState();
  const [booksGroups, setBooksGroups] = useState<string[]>([]);
  const [selectedBookGroup, setSelectedBookGroup] = useState<string | null>(
    null,
  );
  const [selectedBookGroupVariant, setSelectedBookGroupVariant] = useState<
    string | null
  >(null);
  const [openNewVariantDialog, setOpenNewVariantDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setShowFiles(false);
    onClose();
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleOpenNewVariantDialog = () => {
    setOpen(true);
  };

  const handleCloseNewVariantDialog = () => {
    setOpen(false);
  };

  const handleCheckedRight = async () => {
    const loginsABook = leftChecked.map(String);
    const loginsBBook = bBook
      .filter((item) => !leftChecked.includes(item))
      .map(String);

    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      accountInformation
    ) {
      await switchAccount(
        tokens.accessToken,
        accountInformation.userUUID,
        loginsBBook,
        loginsABook,
      );
    }

    setBBook(not(bBook, leftChecked));
    setABook(aBook.concat(leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = async () => {
    const loginsBBook = rightChecked.map(String);
    const loginsABook = aBook
      .filter((item) => !rightChecked.includes(item))
      .map(String);

    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      accountInformation
    ) {
      await switchAccount(
        tokens.accessToken,
        accountInformation.userUUID,
        loginsBBook,
        loginsABook,
      );
    }

    setABook(not(aBook, rightChecked));
    setBBook(bBook.concat(rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: readonly string[]) => (
    <Card>
      <CardHeader
        sx={{
          px: 2,
          py: 1,
          bgcolor: isDarkMode ? '#2b2d30' : '#ffffff',
          color: isDarkMode ? '#FFFFFF' : '#333333',
        }}
        avatar={
          <Checkbox
            sx={{
              color: isDarkMode ? '#FFFFFF' : '#333333',
            }}
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={
          <Typography
            variant="caption"
            sx={{
              color: isDarkMode ? '#FFFFFF' : '#333333',
            }}
          >{`${numberOfChecked(items)}/${items.length} selected`}</Typography>
        }
      />
      <Divider sx={{ backgroundColor: isDarkMode ? '#1b1c1c' : '#ffffff' }} />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: isDarkMode ? '#2b2d30' : '#ffffff',
          color: isDarkMode ? '#FFFFFF' : '#333333',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  sx={{
                    color: isDarkMode ? '#FFFFFF' : '#333333',
                  }}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );
  //! -------------------------

  const tabNames = [
    'Account Informations',
    'Challenges',
    'Funded',
    'Transactions',
    'Affiliations',
    'Actions',
  ];
  const tableTransactionsData = [];
  const tableAffiliationsCommissionsData = [];
  const tableKycFilesData = [];
  const tableCryptoAddressData = [];
  const tableIbanDocumentsData = [];
  const tableTransactionsColumns = [
    'Type',
    'Value',
    'Date',
    'Payment Type',
    'Promo Code',
  ];
  const tableAffiliationsCommissionsColumns = [
    'ID',
    'Name',
    'Price Paid',
    'Commission',
    'Notes',
    'Date',
  ];
  const tableKycFilesColumns = ['ID', 'File', 'Type', 'Status'];
  const tableCryptoAddressColumns = ['ID', 'Wallet Address', 'Date', 'Status'];
  const tableIbanDocumentsColumns = ['ID', 'File', 'Date', 'Status', 'Amount'];
  const challengeAmountOptions = [
    'Select an amount',
    '10000',
    '25000',
    '50000',
    '100000',
    '200000',
  ];

  const size = 5;
  type ActiveChallengesArrayType = activeChallengesResponse[];

  const [personalInformation, setpersonalInformation] =
    useState<personalInformation>();
  const [accountInformation, setAccountInformation] =
    useState<accountInformation>();
  const [financialInformation, setfinancialInformation] =
    useState<financialInformation>();
  const [activeChallenges, setActiveChallenges] = useState<
    activeChallengesResponse[]
  >([]);
  const [activeFundeds, setActiveFundeds] = useState<activeFundedResponse[]>(
    [],
  );

  const [transactions, setTransactions] = useState<TransactionContent[]>([]);
  const [affiliationsCommissions, setAffiliationsCommissions] = useState<
    AffiliationsCommissionsContent[]
  >([]);
  const [kycDocuments, setKycDocuments] = useState<any>([]);
  const [cryptoAddress, setCryptoAddress] = useState<any>([]);
  const [ibanDocuments, setIbanDocuments] = useState<any>([]);
  const [transactionsTotalPages, setTransactionsTotalPages] =
    useState<number>(0);
  const [
    affiliationsCommissionsTotalPages,
    setAffiliationsCommissionsTotalPages,
  ] = useState<number>(0);
  const [transactionPage, setTransactionPage] = useState<number>(0);
  const [affiliationPage, setAffiliationPage] = useState<number>(0);
  const [selectedChallengeAmountOption, setSelectedChallengeAmountOption] =
    useState<string | undefined>(undefined);
  const [amount, setAmount] = useState<number>(0);
  const [affiliationPercentage, setAffiliationPercentage] = useState<number>(0);
  const [affiliationDisplayName, setAffiliationDisplayName] =
    useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [referrerAdminId, setReferrerAdminId] = useState<number>(0);
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [showedFiles, setShowedFiles] = useState<string>('kyc');
  const [error, setError] = useState<string>('');
  const [selectedVariant, setSelectedVariant] = useState('A-Book');
  // const [open, setOpen] = useState<boolean>(false);
  const [affiliateGetPayoutData, setAffiliateGetPayoutData] = useState<any>([]);
  const [paginatedData, setPaginatedData] = useState<{
    [key: number]: { [key: number]: any[] };
  }>({});
  const [totalPages, setTotalPages] = useState<any>({});

  const [fromUserUUID, setFromUserUUID] = useState<string>('');
  const [toUserEmail, setToUserEmail] = useState<string>('');

  const updateTotalPages = (index: number, totalPagesForIndex: number) => {
    setTotalPages((prev: any) => ({ ...prev, [index]: totalPagesForIndex }));
  };
  const [paginationState, setPaginationState] = useState<PaginationItem[]>([]);

  const downloadKycDocuments = useCallback(
    async (id: number, fileName: string) => {
      if (tokens && tokens.accessToken) {
        setIsLoading(true);
        try {
          downloadKycDocument(tokens.accessToken, id, fileName);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        logout();
      }
    },
    [tokens],
  );

  const commissionsGetPayoutColumns = [
    t('tables.commissions.id'),
    t('tables.commissions.name'),
    t('tables.commissions.pricePaid'),
    t('tables.commissions.commissionsEarned'),
    t('tables.commissions.notes'),
    t('tables.commissions.transactionDate'),
  ];

  const getBooks = useCallback(async () => {
    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      accountInformation
    ) {
      const response = await getFundedByReferrerUUID(
        tokens.accessToken,
        accountInformation.userUUID,
      );

      setABook(response.loginsABook);
      setBBook(response.loginsBBook);
    }
  }, [isTokensLoading, tokens, accountInformation]);

  const handleGetBooksGroups = useCallback(async () => {
    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      accountInformation
    ) {
      const response = await getGroupsByReferrer(
        tokens.accessToken,
        accountInformation.userUUID,
      );

      setBooksGroups(response);
    }
  }, [isTokensLoading, tokens, accountInformation]);

  const handleDefineDefaultBooksGroup = () => {
    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      accountInformation &&
      selectedBookGroup
    ) {
      setDefaultGroup(
        tokens.accessToken,
        selectedBookGroup,
        accountInformation.userUUID,
      );
    }
  };

  const handleCreateNewVariant = () => {
    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      accountInformation &&
      selectedBookGroupVariant
    ) {
      createNewBooksVariant(
        tokens.accessToken,
        selectedBookGroupVariant,
        accountInformation.userUUID,
      );
    }
  };

  const handleGetSelectedBooksGroups = (event: any, value: string | null) => {
    setSelectedBookGroup(value);
  };

  const handleGetSelectedBooksGroupsVariant = (
    event: any,
    value: string | null,
  ) => {
    setSelectedBookGroupVariant(value);
  };

  useEffect(() => {
    if (!isTokensLoading) {
      getBooks();
    }
  }, [getBooks, isTokensLoading]);

  useEffect(() => {
    const initialPaginationState = affiliateGetPayoutData.map(
      (_: any, index: number) => ({
        index,
        currentPage: 0,
      }),
    );

    setPaginationState(initialPaginationState);
  }, [affiliateGetPayoutData]);

  const updatePaginationState = (index: number, newPage: number) => {
    const newState = paginationState.map((item) =>
      item.index === index ? { ...item, currentPage: newPage } : item,
    );

    setPaginationState(newState);
  };

  const updatePaginatedData = useCallback(
    (tableIndex: number, page: number, newData: any) => {
      setPaginatedData((prev) => ({
        ...prev,
        [tableIndex]: {
          ...(prev[tableIndex] || {}),
          [page]: newData,
        },
      }));
    },
    [setPaginatedData],
  );

  const fetchAffiliationsCommissionsGetPayout = useCallback(
    async (tableIndex: number, page: number) => {
      if (!tokens || !tokens.accessToken) return;

      setIsLoading(true);
      try {
        const payoutMonth = affiliateGetPayoutData[tableIndex];
        if (!payoutMonth) return;

        const response = await fetchAffiliateCommissionsPayoutRequest(
          tokens.accessToken,
          uuid,
          payoutMonth.monthValue,
          payoutMonth.year,
          page,
          size,
        );

        updateTotalPages(tableIndex, response.totalPages);
        updatePaginatedData(tableIndex, page, response.content);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [tokens, affiliateGetPayoutData, size, updatePaginatedData],
  );

  useEffect(() => {
    if (!isTokensLoading && tokens && tokens.accessToken) {
      setIsLoading(true);
      paginationState.forEach(({ index, currentPage }) => {
        fetchAffiliationsCommissionsGetPayout(index, currentPage);
      });
      setIsLoading(false);
    }
  }, [
    paginationState,
    tokens,
    isTokensLoading,
    fetchAffiliationsCommissionsGetPayout,
  ]);

  useEffect(() => {
    if (
      !isTokensLoading &&
      tokens &&
      tokens.accessToken &&
      affiliateGetPayoutData.length > 0
    ) {
      setIsLoading(true);
      affiliateGetPayoutData.forEach((_: any, index: any) => {
        fetchAffiliationsCommissionsGetPayout(index, 0);
      });
      setIsLoading(false);
    }
  }, [
    tokens,
    isTokensLoading,
    affiliateGetPayoutData,
    fetchAffiliationsCommissionsGetPayout,
  ]);

  for (const transaction of transactions) {
    tableTransactionsData.push({
      ID: transaction.id,
      Type: transaction.type,
      Value: transaction.value,
      Date: transaction.date,
      'Payment Type': transaction.paymentType,
      'Promo Code': transaction.promoCode,
    });
  }

  for (const affiliation of affiliationsCommissions) {
    tableAffiliationsCommissionsData.push({
      ID: affiliation.id,
      Name: affiliation.name,
      'Price Paid': affiliation.pricePaid,
      Commission: affiliation.commissionEarned,
      Notes: affiliation.notes,
      Date: affiliation.transactionDate,
    });
  }

  for (const document of kycDocuments) {
    tableKycFilesData.push({
      ID: document.id,
      File: (
        <span
          className={classes.downloadButton}
          onClick={() =>
            downloadKycDocuments(
              document.id,
              `KYC_${document.id}_${document.originalFileName}`,
            )
          }
        >
          <Icon
            color="white"
            size={16}
            path="M13 10H18L12 16L6 10H11V3H13V10ZM4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19Z"
          />
          {document.originalFileName}
        </span>
      ),
      Type: document.documentType,
      Status: (
        <Stack
          sx={{
            color:
              document.status === 'APPROVED'
                ? 'green'
                : document.status === 'REJECTED'
                ? 'red'
                : document.status === 'PENDING'
                ? 'yellow'
                : 'white',
          }}
        >
          {document.status}
        </Stack>
      ),
    });
  }

  for (const address of cryptoAddress) {
    tableCryptoAddressData.push({
      ID: address.id,
      'Wallet Address': address.walletAddress,
      Date: address.date,
      Status: address.withdrawStatus,
    });
  }

  for (const document of ibanDocuments) {
    tableIbanDocumentsData.push({
      ID: document.id,
      File: (
        <span
          className={classes.downloadButton}
          onClick={() => downloadIbanDocuments(document.id, document.fileName)}
        >
          <Icon
            color="white"
            size={16}
            path="M13 10H18L12 16L6 10H11V3H13V10ZM4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19Z"
          />
          {document.fileName}
        </span>
      ),
      Date: document.requestDate,
      Status: document.status,
      Amount: document.amount,
    });
  }

  // TODO DEMAIN
  const handleSubmitCreateVariant = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    if (tokens && tokens.accessToken && accountInformation && selectedVariant) {
      setIsLoading(true);
      try {
        await createVariant(
          tokens.accessToken,
          selectedVariant,
          accountInformation.userAdminID,
        );
        toast.success('Variant created successfully');
      } catch (error) {
        toast.error('An error occured creating the variant');
        console.error(error);
      } finally {
        setIsLoading(false);
        setOpen(false);
      }
    }
  };

  const handleSelect = (selectedOption: string) => {
    setSelectedVariant(selectedOption);
  };

  const handleOpenVariant = () => {
    setOpen(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  const handleSubmitSwitchRankedPercentage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        switchAffiliationToPercentage(tokens.accessToken, uuid, 10, false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitResendCredentials = async (e: React.FormEvent) => {
    e.preventDefault();
    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        if (personalInformation !== undefined) {
          resentCredentials(tokens.accessToken, personalInformation.email);
          toast.success('Mail sent successfully.');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitSwitchFixedPercentage = async (e: React.FormEvent) => {
    e.preventDefault();

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        switchAffiliationToPercentage(
          tokens.accessToken,
          uuid,
          affiliationPercentage,
          true,
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitDisplayName = async (e: React.FormEvent) => {
    e.preventDefault();

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        changeAffiliationDisplayName(
          tokens.accessToken,
          uuid,
          affiliationDisplayName,
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitUserReferrer = async (e: React.FormEvent) => {
    e.preventDefault();

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        changeUserReferrer(tokens.accessToken, uuid, referrerAdminId);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        userAccount();
      }
    }
  };

  const handleSubmitSwitchRank = async (e: React.FormEvent) => {
    e.preventDefault();

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        switchAffiliationToRank(tokens.accessToken, uuid);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSelectChallengeAmount = (option: string) => {
    if (option && option !== 'Select an amount') {
      setSelectedChallengeAmountOption(option);
      setError('');
    } else {
      setError('Please select a valid option');
    }
  };

  const changeTab = (id: string, index: number) => {
    setActiveTab(id, index);
  };

  const userAccount = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await accountInformations(tokens.accessToken, uuid);
        setpersonalInformation(response.personalInformation);
        setAccountInformation(response.accountInformation);
        setfinancialInformation(response.financialInformation);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid]);

  const fetchActiveChallenges = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await getAllActiveChallenges(tokens.accessToken, uuid);
        setActiveChallenges(response); // response devrait déjà être un tableau

        console.log(activeChallenges);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid]);

  const fetchActiveFunded = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await getAllActiveFunded(tokens.accessToken, uuid);
        setActiveFundeds(response); // response devrait déjà être un tableau

        console.log(activeFundeds);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid]);

  const fetchTransactions = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await getAllTransactions(
          tokens.accessToken,
          uuid,
          transactionPage,
          size,
        );
        setTransactions(response.content);
        setTransactionsTotalPages(response.totalPages);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid, transactionPage, size]);

  const fetchAffiliationsCommissions = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await getAllAffiliationsCommissions(
          tokens.accessToken,
          uuid,
          affiliationPage,
          size,
        );
        setAffiliationsCommissions(response.content);
        setAffiliationsCommissionsTotalPages(response.totalPages);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid, affiliationPage, size]);

  const fetchKycDocuments = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await getKycDocuments(tokens.accessToken, uuid);
        setKycDocuments(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid]);

  const fetchCryptoAddress = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await getCryptoAddress(tokens.accessToken, uuid);
        setCryptoAddress(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid]);

  const handleFetchAffiliatePayoutRequest = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);

      try {
        const response = await fetchAffiliatePayoutRequest(
          tokens.accessToken,
          uuid,
        );
        setAffiliateGetPayoutData(response.payoutMonths);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid]);

  const fetchIbanDocuments = useCallback(async () => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        const response = await getIbanDocuments(tokens.accessToken, uuid);
        setIbanDocuments(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      logout();
    }
  }, [tokens, uuid]);

  const downloadIbanDocuments = useCallback(
    async (id: number, fileName: string) => {
      if (tokens && tokens.accessToken) {
        setIsLoading(true);
        try {
          downloadIbanDocument(tokens.accessToken, id, fileName);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        logout();
      }
    },
    [tokens],
  );

  const handleSubmitGiveChallenge = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      tokens &&
      tokens.accessToken &&
      uuid &&
      selectedChallengeAmountOption &&
      selectedChallengeAmountOption !== 'Select an amount'
    ) {
      setIsLoading(true);
      try {
        await giveChallengeToUser(
          tokens.accessToken,
          uuid,
          Number(selectedChallengeAmountOption),
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        fetchActiveChallenges();
      }
    }
  };

  const handleDeleteFundedChallenge = async (account: string) => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        await deleteFundedChallenge(tokens.accessToken, account);
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
        fetchActiveFunded();
      }
    }
  };

  const handleDeleteChallenge = async (account: string) => {
    if (tokens && tokens.accessToken) {
      setIsLoading(true);
      try {
        await deleteChallenge(tokens.accessToken, account);
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
        fetchActiveChallenges();
      }
    }
  };

  const handleSubmitUpdateWallet = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!amount || amount === 0) {
      return setError('Please select a valid amount.');
    } else {
      setError('');
    }

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        updateUserWallet(tokens.accessToken, uuid, amount);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitMergeAccounts = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!toUserEmail) {
      return setError('Please enter the accounts informations');
    } else {
      setError('');
    }

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        mergeAccounts(tokens.accessToken, uuid, toUserEmail);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitUpdateEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!userEmail) {
      return setError('Please enter a valid email.');
    } else {
      setError('');
    }

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        updateUserEmail(tokens.accessToken, uuid, userEmail);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleSubmitModifyPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!password || password === '') {
      return setError('Please enter a password');
    } else {
      setError('');
    }

    if (tokens && tokens.accessToken && uuid) {
      setIsLoading(true);
      try {
        modifyPassword(tokens.accessToken, uuid, password);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteAffiliationCommissions = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await deleteAffiliationCommission(tokens.accessToken, id);
      fetchAffiliationsCommissions();
    } else {
      logout();
    }
  };

  const handleSwitchPaymentAffiliate = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await switchPaymentAffiliate(tokens.accessToken, id);
      fetchTransactions();
    } else {
      logout();
    }
  };

  const handleDeleteKycDocument = async (id: number) => {
    if (tokens && tokens.accessToken) {
      await deleteKycDocument(tokens.accessToken, id);
      fetchKycDocuments();
    } else {
      logout();
    }
  };

  const handleCloseVariantModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isTokensLoading && uuid) {
      userAccount();
      fetchActiveChallenges();
      fetchActiveFunded();
      fetchTransactions();
      fetchAffiliationsCommissions();
      handleFetchAffiliatePayoutRequest();
    }
  }, [
    userAccount,
    fetchActiveChallenges,
    fetchActiveFunded,
    fetchTransactions,
    isTokensLoading,
    fetchAffiliationsCommissions,
    handleFetchAffiliatePayoutRequest,
    uuid,
  ]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: isDarkMode ? '#262729' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#262729',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  return (
    <MUIModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          sx={{ marginBottom: '24px', fontWeight: 'bold' }}
        >
          {`${personalInformation?.fullname}'s File` || "User's File"}
        </Typography>
        <Dialog
          open={open}
          onClose={handleCloseVariantModal}
          PaperProps={{
            sx: {
              backgroundColor: 'grey.900', // Fond gris foncé
              color: 'white', // Texte blanc
            },
          }}
        >
          <form onSubmit={handleSubmitCreateVariant}>
            <DialogTitle sx={{ color: 'white' }}>Add a new variant</DialogTitle>
            <DialogContent>
              <Select
                id="amount"
                options={['A-Book', 'B-Book']}
                onSelect={handleSelect}
              />
            </DialogContent>
            <DialogActions>
              <ButtonMUI
                onClick={handleCloseVariantModal}
                sx={{ color: 'white' }}
              >
                Cancel
              </ButtonMUI>
              <ButtonMUI type="submit" sx={{ color: 'white' }}>
                Add
              </ButtonMUI>
            </DialogActions>
          </form>
        </Dialog>

        {isLoading && !uuid && !activeChallenges ? (
          <Loader />
        ) : (
          <div className={classes.wrapper}>
            <Tab id="userProfile" tabs={tabNames}>
              {showFiles ? (
                <>
                  <Button
                    className={classes.returnButton}
                    variant="outline"
                    onClick={() => setShowFiles(false)}
                  >
                    <Icon
                      color="white"
                      size={16}
                      path="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"
                    />
                    Return
                  </Button>
                  {showedFiles === 'kyc' ? (
                    <>
                      <h2 className={classes.title}>KYC Documents</h2>
                      <UserProfileTable
                        data={tableKycFilesData}
                        columns={tableKycFilesColumns}
                        deleteButton={true}
                        onDeleteButtonClick={handleDeleteKycDocument}
                      />
                    </>
                  ) : showedFiles === 'iban' ? (
                    <>
                      <h2 className={classes.title}>Iban Documents</h2>
                      <UserProfileTable
                        data={tableIbanDocumentsData}
                        columns={tableIbanDocumentsColumns}
                      />
                    </>
                  ) : showedFiles === 'wallet' ? (
                    <>
                      <h2 className={classes.title}>Wallet Address</h2>
                      <UserProfileTable
                        data={tableCryptoAddressData}
                        columns={tableCryptoAddressColumns}
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <div>
                  <div className={classes.accountContainer}>
                    <div className={classes.personalInformations}>
                      <h2 className={classes.title}>Personal Informations</h2>
                      <ul className={classes.list}>
                        <li>
                          <b>Title:</b> {personalInformation?.title}
                        </li>
                        <li>
                          <b>Birthdate:</b> {personalInformation?.birthdate}
                        </li>
                        <li>
                          <b>Email:</b> {personalInformation?.email}
                        </li>
                        <li>
                          <b>Phone number:</b> {personalInformation?.phone}
                        </li>
                        <li>
                          <b>Address:</b> {personalInformation?.address}
                        </li>
                        <li>
                          <b>Creation date:</b>{' '}
                          {personalInformation?.creationDate}
                        </li>
                      </ul>
                    </div>
                    <div className={classes.accountInformations}>
                      <h2 className={classes.title}>Account Informations</h2>
                      <ul className={classes.list}>
                        <li>
                          <b>Role:</b> {accountInformation?.role}
                        </li>
                        <li>
                          <Stack
                            direction={'row'}
                            gap={2}
                            alignItems={'center'}
                          >
                            <Typography fontWeight={'bold'}>KYC:</Typography>
                            <Typography
                              sx={{
                                fontSize: '18px',
                                color:
                                  accountInformation?.kycStatus === 'Validated'
                                    ? 'green'
                                    : 'red',
                              }}
                            >
                              <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={1}
                              >
                                {accountInformation?.kycStatus ===
                                'Validated' ? (
                                  <CheckIcon />
                                ) : (
                                  <CloseIcon />
                                )}
                                {accountInformation?.kycStatus}
                              </Stack>
                            </Typography>
                          </Stack>
                        </li>
                        <li>
                          <b>Referred by:</b>
                          {` ${accountInformation?.referredBy.name} (${accountInformation?.referredBy.adminId})`}
                        </li>
                        <li>
                          <b>IP Address:</b> {accountInformation?.ipAddress}
                        </li>
                        <li>
                          <b>Last Login:</b> {accountInformation?.lastLogin}
                        </li>
                        <li>
                          <b>UUID:</b> {accountInformation?.userUUID}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.accountContainer}>
                    <div className={classes.financialInformations}>
                      <h2 className={classes.title}>Financial Informations</h2>
                      <ul className={classes.list}>
                        <li>
                          <b>Wallet Balance:</b>{' '}
                          {financialInformation?.walletBalance}
                        </li>
                        <li>
                          <b>Previous Wallet Balance:</b>{' '}
                          {financialInformation?.previousWalletBalance}
                        </li>
                      </ul>
                      <div className={classes.buttons}>
                        <Button
                          variant="border-bottom"
                          onClick={() => changeTab('userProfile', 2)}
                        >
                          View transactions
                        </Button>
                        <Button
                          variant="border-bottom"
                          onClick={() => changeTab('userProfile', 4)}
                        >
                          Edit wallet
                        </Button>
                      </div>
                    </div>
                    <div className={classes.files}>
                      <h2 className={classes.title}>Files</h2>
                      <div className={classes.buttons}>
                        <Button
                          variant="border-bottom"
                          onClick={() => {
                            setShowFiles(true);
                            setShowedFiles('kyc');
                            fetchKycDocuments();
                          }}
                        >
                          View KYC files
                        </Button>
                        <Button
                          variant="border-bottom"
                          onClick={() => {
                            setShowFiles(true);
                            setShowedFiles('wallet');
                            fetchCryptoAddress();
                          }}
                        >
                          View Wallet files
                        </Button>
                      </div>
                      <h2 className={classes.title}>Quick Actions</h2>
                      {error && <Error error={error} />}
                      <form
                        onSubmit={handleSubmitModifyPassword}
                        className={classes.formWrapper}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="password">Password</label>
                          <Input
                            id="password"
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <Button type="submit" variant="primary">
                          Modify password
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              <div className={classes.challengesContainer}>
                {activeChallenges.map(
                  (challenge: activeChallengesResponse, index: number) => (
                    <Dropdown
                      key={index}
                      login={challenge.login}
                      stageOfChallenge={challenge.stageOfChallenge}
                      balance={challenge.balance}
                      equity={challenge.equity}
                      maxDailyLossAllowed={challenge.maxDailyLossAllowed}
                      maxDrawdown={challenge.maxDrawdown}
                      numberOfTrades={challenge.numberOfTrades}
                      balancePrevDay={challenge.balancePrevDay}
                      isHFTEnabled={challenge.hftEnabled}
                      onSwitchHFT={fetchActiveChallenges}
                      onSwitchDemo={fetchActiveChallenges}
                      onDelete={true}
                      onDeleteClick={() =>
                        handleDeleteChallenge(challenge.login)
                      }
                    >
                      <div className={classes.objectives}>
                        <table
                          className={`${classes.table} ${
                            isDarkMode ? classes.dark : classes.light
                          }`}
                        >
                          <thead className={classes.table__header}>
                            <tr>
                              <th colSpan={2}>
                                {t(
                                  'accountCard.objectivesTable.tradingObjectives',
                                  {
                                    ns: 'components',
                                  },
                                )}
                              </th>
                              <th>
                                {t('accountCard.objectivesTable.profitTarget', {
                                  ns: 'components',
                                })}
                              </th>
                              <th>
                                {t(
                                  'accountCard.objectivesTable.currentResult',
                                  {
                                    ns: 'components',
                                  },
                                )}
                              </th>
                              <th>
                                {t('accountCard.objectivesTable.state', {
                                  ns: 'components',
                                })}
                              </th>
                              <th>Valeur limite</th>
                            </tr>
                          </thead>
                          <tbody className={classes.table__body}>
                            <tr>
                              <td colSpan={2}>
                                {t('accountCard.objectivesTable.maxDailyLoss', {
                                  ns: 'components',
                                })}
                              </td>
                              <td>
                                {challenge?.maxDailyLossPassed.targetValue}$
                              </td>
                              <td>
                                {challenge?.maxDailyLossPassed.currentValue}$
                              </td>
                              <td className={classes.state}>
                                <Icon
                                  size={18}
                                  color=""
                                  fill={
                                    challenge?.maxDailyLossPassed.passed
                                      ? '#1fcc92'
                                      : '#e74750'
                                  }
                                  path={
                                    challenge?.maxDailyLossPassed.passed
                                      ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                      : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                                  }
                                />
                                {challenge?.maxDailyLossPassed.passed
                                  ? t('accountCard.objectivesTable.passed', {
                                      ns: 'components',
                                    })
                                  : t('accountCard.objectivesTable.notPassed', {
                                      ns: 'components',
                                    })}
                              </td>
                              <td>
                                {challenge?.maxDailyLossPassed.limitValue}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                {t('accountCard.objectivesTable.maxLoss', {
                                  ns: 'components',
                                })}
                              </td>
                              <td>{challenge?.maxLossPassed.targetValue}$</td>
                              <td>{challenge?.maxLossPassed.currentValue}$</td>
                              <td className={classes.state}>
                                <Icon
                                  size={18}
                                  color=""
                                  fill={
                                    challenge?.maxLossPassed.passed
                                      ? '#1fcc92'
                                      : '#e74750'
                                  }
                                  path={
                                    challenge?.maxLossPassed.passed
                                      ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                      : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                                  }
                                />
                                {challenge?.maxLossPassed.passed
                                  ? t('accountCard.objectivesTable.passed', {
                                      ns: 'components',
                                    })
                                  : t('accountCard.objectivesTable.notPassed', {
                                      ns: 'components',
                                    })}
                              </td>
                              <td>{challenge?.maxLossPassed.limitValue}</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                {t('accountCard.objectivesTable.profitTarget', {
                                  ns: 'components',
                                })}
                              </td>
                              <td>
                                {challenge?.profitTargetPassed.targetValue}$
                              </td>
                              <td>
                                {challenge?.profitTargetPassed.currentValue}$
                              </td>
                              <td className={classes.state}>
                                <Icon
                                  size={18}
                                  color=""
                                  fill={
                                    challenge?.profitTargetPassed.passed
                                      ? '#1fcc92'
                                      : '#e74750'
                                  }
                                  path={
                                    challenge?.profitTargetPassed.passed
                                      ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                      : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                                  }
                                />
                                {challenge?.profitTargetPassed.passed
                                  ? t('accountCard.objectivesTable.passed', {
                                      ns: 'components',
                                    })
                                  : t('accountCard.objectivesTable.notPassed', {
                                      ns: 'components',
                                    })}
                              </td>
                              <td>
                                {challenge?.profitTargetPassed.limitValue}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>45% Rule</td>
                              <td>45%</td>
                              <td>
                                {challenge?.consistencyRule.currentPercentage}%
                              </td>
                              <td className={classes.state}>
                                <Icon
                                  size={18}
                                  color=""
                                  fill={
                                    challenge?.consistencyRule.valid
                                      ? '#1fcc92'
                                      : '#e74750'
                                  }
                                  path={
                                    challenge?.consistencyRule.valid
                                      ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                      : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                                  }
                                />
                                {challenge?.consistencyRule.valid
                                  ? t('accountCard.objectivesTable.passed', {
                                      ns: 'components',
                                    })
                                  : t('accountCard.objectivesTable.notPassed', {
                                      ns: 'components',
                                    })}
                              </td>
                              <td>
                                {challenge?.consistencyRule.minimumPayoutValue}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Dropdown>
                  ),
                )}
              </div>

              <div className={classes.challengesContainer}>
                {activeFundeds.map(
                  (funded: activeFundedResponse, index: number) => (
                    <Dropdown
                      key={index}
                      login={funded.login}
                      balance={funded.balance}
                      equity={funded.equity}
                      maxDailyLossAllowed={funded.maxDailyLossAllowed}
                      maxDrawdown={funded.maxDrawdown}
                      balancePrevDay={funded.balancePrevDay}
                      numberOfTrades={funded.numberOfTrades}
                      group={funded.group}
                      userAdminID={accountInformation?.userAdminID}
                      isFunded={true}
                      onSwitchGroup={fetchActiveChallenges}
                      onAddVariant={() => handleOpenVariant()}
                      onDelete={true}
                      onDeleteClick={() =>
                        handleDeleteFundedChallenge(funded.login)
                      }
                    >
                      <div className={classes.objectives}>
                        <br />
                        <h2>Next payout date: {funded.nextPayoutDate}</h2>
                        <table
                          className={`${classes.table} ${
                            isDarkMode ? classes.dark : classes.light
                          }`}
                        >
                          <thead className={classes.table__header}>
                            <tr>
                              <th colSpan={2}>
                                {t(
                                  'accountCard.objectivesTable.tradingObjectives',
                                  {
                                    ns: 'components',
                                  },
                                )}
                              </th>
                              <th>
                                {t('accountCard.objectivesTable.profitTarget', {
                                  ns: 'components',
                                })}
                              </th>
                              <th>
                                {t(
                                  'accountCard.objectivesTable.currentResult',
                                  {
                                    ns: 'components',
                                  },
                                )}
                              </th>
                              <th>
                                {t('accountCard.objectivesTable.state', {
                                  ns: 'components',
                                })}
                              </th>
                              <th>Valeur limite</th>
                            </tr>
                          </thead>
                          <tbody className={classes.table__body}>
                            <tr>
                              <td colSpan={2}>45% Rules</td>
                              <td>45%</td>
                              <td>
                                {funded?.consistencyRule.currentPercentage}%
                              </td>
                              <td className={classes.state}>
                                <Icon
                                  size={18}
                                  color=""
                                  fill={
                                    funded?.consistencyRule.valid
                                      ? '#1fcc92'
                                      : '#e74750'
                                  }
                                  path={
                                    funded?.consistencyRule.valid
                                      ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                      : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                                  }
                                />
                                {funded?.consistencyRule.valid
                                  ? t('accountCard.objectivesTable.passed', {
                                      ns: 'components',
                                    })
                                  : t('accountCard.objectivesTable.notPassed', {
                                      ns: 'components',
                                    })}
                              </td>
                              <td>
                                {funded?.consistencyRule.minimumPayoutValue}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Dropdown>
                  ),
                )}
              </div>

              <div>
                <UserProfileTable
                  data={tableTransactionsData}
                  columns={tableTransactionsColumns}
                  affiliateButton={true}
                  onAffiliationButtonClick={handleSwitchPaymentAffiliate}
                />
                <Pagination
                  total={transactionsTotalPages}
                  perPage={size}
                  currentPage={transactionPage}
                  onPageChange={(page) => setTransactionPage(page)}
                />
              </div>

              <div className={classes.affiliateDropdownContainer}>
                <Accordion
                  sx={{
                    backgroundColor: isDarkMode ? '#2b2d30' : '#f4f4f4',
                    color: isDarkMode ? '#FFFFFF' : '#333333',
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: isDarkMode ? '#FFFFFF' : '#333333' }}
                      />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Execution Configuration
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack>
                      <Typography variant="h6" gutterBottom>
                        Change default Funded MetaTrader group
                      </Typography>
                      <Stack direction={'row'} spacing={2} width={'100%'}>
                        {tokens != null &&
                          accountInformation?.userAdminID != null && (
                            <Autocomplete
                              disablePortal
                              id="combo-box-books"
                              options={booksGroups}
                              sx={{
                                width: 300,
                                borderColor: isDarkMode ? '#FFFFFF' : '#333333',
                                '& .MuiOutlinedInput-root': {
                                  color: isDarkMode ? 'white' : 'black',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: isDarkMode ? 'black' : 'white',
                                  },
                                  '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: isDarkMode ? 'white' : 'black',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                    {
                                      borderColor: 'primary.main',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                  color: isDarkMode ? 'white' : 'black',
                                },
                                '& .MuiSvgIcon-root': {
                                  color: isDarkMode ? 'white' : 'black',
                                },
                              }}
                              onChange={handleGetSelectedBooksGroups}
                              renderInput={(params) => (
                                <TextField
                                  onClick={handleGetBooksGroups}
                                  {...params}
                                  label="Groups"
                                />
                              )}
                            />
                          )}
                        <Button
                          onClick={handleDefineDefaultBooksGroup}
                          variant="primary"
                        >
                          Apply
                        </Button>
                        <Button
                          onClick={handleOpenNewVariantDialog}
                          variant="rounded-outline"
                        >
                          Add new variant
                        </Button>
                      </Stack>
                    </Stack>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        component: 'form',
                        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                          event.preventDefault();
                          const formData = new FormData(event.currentTarget);
                          const formJson = Object.fromEntries(
                            (formData as any).entries(),
                          );
                          const email = formJson.email;
                          console.log(email);
                          handleClose();
                        },
                      }}
                    >
                      <DialogTitle>Add a new variant</DialogTitle>
                      <DialogContent sx={{ height: 150, paddingTop: 2 }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-books"
                          options={['A-Book', 'B-Book']}
                          sx={{ width: 300 }}
                          onChange={handleGetSelectedBooksGroupsVariant}
                          renderInput={(params) => (
                            <TextField {...params} label="Groups" />
                          )}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="red-outline"
                          onClick={handleCloseNewVariantDialog}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleCreateNewVariant}
                        >
                          Add variant
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Stack marginTop={4}>
                      <Typography variant="h5" gutterBottom>
                        Book users
                      </Typography>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>{customList('B-Book', bBook)}</Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Button
                              variant="outline"
                              onClick={handleCheckedRight}
                              disabled={leftChecked.length === 0}
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                            <br />
                            <Button
                              variant="outline"
                              onClick={handleCheckedLeft}
                              disabled={rightChecked.length === 0}
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>{customList('A-Book', aBook)}</Grid>
                      </Grid>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                {affiliateGetPayoutData.map((payoutMonth: any, index: any) => {
                  const currentPage =
                    paginationState.find((item) => item.index === index)
                      ?.currentPage || 0;
                  const currentData = paginatedData[index]?.[currentPage] || [];

                  return (
                    <PayoutDropdown
                      key={index}
                      month={payoutMonth.month}
                      year={payoutMonth.year}
                      amount={payoutMonth.amount}
                      button={
                        payoutMonth.alreadyProcessed ? (
                          <MuiTooltip
                            title={`Processed the ${payoutMonth.processDate}`}
                            followCursor
                          >
                            <div className={classes.greenButton}>Processed</div>
                          </MuiTooltip>
                        ) : payoutMonth.alreadyRequested ? (
                          <MuiTooltip
                            title={`Will be processed ${payoutMonth.processDate}`}
                            followCursor
                          >
                            <div className={classes.redButton}>In Review</div>
                          </MuiTooltip>
                        ) : (
                          <MuiTooltip
                            title={`Will in review the ${payoutMonth.processDate}`}
                            followCursor
                          >
                            <div className={classes.primaryButton}>
                              Not Available
                            </div>
                          </MuiTooltip>
                        )
                      }
                    >
                      <UserProfileTable
                        data={currentData.map((detail) => ({
                          [t('tables.commissions.id')]: detail.id,
                          [t('tables.commissions.name')]: detail.name,
                          [t('tables.commissions.pricePaid')]: detail.pricePaid,
                          [t('tables.commissions.commissionsEarned')]:
                            detail.commissionEarned,
                          [t('tables.commissions.notes')]: detail.notes,
                          [t('tables.commissions.transactionDate')]:
                            detail.transactionDate,
                        }))}
                        columns={commissionsGetPayoutColumns}
                        deleteButton={true}
                        onDeleteButtonClick={handleDeleteAffiliationCommissions}
                      />
                      <Pagination
                        total={totalPages[index] || 0}
                        perPage={size}
                        currentPage={currentPage}
                        onPageChange={(newPage) =>
                          updatePaginationState(index, newPage)
                        }
                      />
                    </PayoutDropdown>
                  );
                })}
              </div>

              <div className={classes.container}>
                {error && <Error error={error} />}
                <div className={classes.forms}>
                  <details className={classes.dropdown}>
                    <summary>Challenge and Reward Management</summary>

                    <div className={classes.container}>
                      <form
                        onSubmit={handleSubmitGiveChallenge}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amount">Give challenge to user</label>
                          <Select
                            id="amount"
                            options={challengeAmountOptions}
                            onSelect={handleSelectChallengeAmount}
                          />
                        </div>
                        <Button variant="primary" type="submit">
                          Give a challenge
                        </Button>
                      </form>

                      <form
                        onSubmit={handleSubmitUpdateWallet}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amountWallet">
                            Modify user virtual wallet
                          </label>
                          <Input
                            id="amountWallet"
                            type="number"
                            min={0}
                            placeholder="Enter amount"
                            onKeyDown={handleKeyDown}
                            value={amount}
                            onChange={(e) => setAmount(Number(e.target.value))}
                          />
                        </div>
                        <Button variant="primary" type="submit">
                          Modify wallet
                        </Button>
                      </form>
                    </div>
                  </details>

                  <details className={classes.dropdown}>
                    <summary>Affiliation Management</summary>
                    <div className={classes.container}>
                      <form
                        onSubmit={handleSubmitSwitchFixedPercentage}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amount">
                            Switch affiliation to precise percentage
                          </label>
                        </div>
                        <Input
                          id="affiliationPercentage"
                          placeholder="number, 10, 20 etc. (percentage)"
                          onKeyDown={handleKeyDown}
                          value={affiliationPercentage}
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setAffiliationPercentage(Number(e.target.value))
                          }
                        />
                        <Button variant="primary" type="submit">
                          Switch affiliation to percentage
                        </Button>
                      </form>

                      <form
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label>
                            Switch affiliation to percentage with ranks (silver,
                            gold, platinium)
                          </label>
                        </div>
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={handleSubmitSwitchRankedPercentage}
                        >
                          Switch affiliation to percentage with rank
                        </Button>
                      </form>

                      <form
                        onSubmit={handleSubmitSwitchRank}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amount">
                            Switch affiliation to rank (default)
                          </label>
                        </div>
                        <Button variant="primary" type="submit">
                          Switch to rank
                        </Button>
                      </form>

                      <form
                        onSubmit={handleSubmitDisplayName}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amount">
                            Set affiliation display name
                          </label>
                          <label className={classes.example}>
                            exemple:
                            'link'/buy-challenge?affiliateId=SuperAffiliate
                          </label>
                        </div>
                        <Input
                          id="affiliationDisplayName"
                          placeholder="Warren-Buffet, FamilyTR, RimRai"
                          value={affiliationDisplayName}
                          type="string"
                          onChange={(e) =>
                            setAffiliationDisplayName(
                              e.target.value.replace(/\s+/g, ''),
                            )
                          }
                        />
                        <Button variant="primary" type="submit">
                          Set affiliation display name
                        </Button>
                      </form>
                    </div>
                  </details>

                  <details className={classes.dropdown}>
                    <summary>User Management</summary>
                    <div className={classes.container}>
                      <form
                        onSubmit={handleSubmitUserReferrer}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amount">Change user referrer</label>
                          <label className={classes.example}>
                            Enter the referrer admin ID
                          </label>
                        </div>
                        <Input
                          id="referrerAdminId"
                          placeholder="1810"
                          value={referrerAdminId}
                          type="string"
                          onChange={(e) =>
                            setReferrerAdminId(parseInt(e.target.value))
                          }
                        />
                        <Button variant="primary" type="submit">
                          Change user referrer
                        </Button>
                      </form>

                      <form
                        onSubmit={handleSubmitResendCredentials}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amount">
                            Resend credentials (Warning : this will regenerate
                            the credentials)
                          </label>
                        </div>
                        <Button variant="primary" type="submit">
                          Resend credentials
                        </Button>
                      </form>

                      <form
                        onSubmit={handleSubmitUpdateEmail}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label htmlFor="amountWallet">
                            Modify user email
                          </label>
                          <Input
                            id="userEmail"
                            placeholder="example@gmail.com"
                            value={userEmail}
                            type="string"
                            onChange={(e) =>
                              setUserEmail(e.target.value.replace(/\s+/g, ''))
                            }
                          />
                        </div>
                        <Button variant="primary" type="submit">
                          Modify email
                        </Button>
                      </form>

                      <form
                        onSubmit={handleSubmitMergeAccounts}
                        className={`${classes.formWrapper} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        <div className={classes.formGroup}>
                          <label>Merge accounts</label>
                          <Input
                            id="toUserMail"
                            placeholder="To account email"
                            value={toUserEmail}
                            type="string"
                            onChange={(e) => setToUserEmail(e.target.value)}
                          />
                        </div>
                        <Button variant="primary" type="submit">
                          Merge accounts
                        </Button>
                      </form>
                    </div>
                  </details>
                </div>
              </div>
            </Tab>
          </div>
        )}
      </Box>
    </MUIModal>
  );
};
export default UserProfile;
