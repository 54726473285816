import React from 'react';
import { useHandleCopy } from '../hooks/useHandleCopy';
import { useHandleDownload } from '../hooks/useHandleDownload';
import { CertificateResponse } from '../services/get/certificate/models';
import { yellow } from '../../../../themes/colors/colors';
import { formatDate } from '../../../../utils/formatDate';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import classes from '../styles/certificate.module.scss';
import { useTranslation } from 'react-i18next';

interface CertificateCardProps {
  certificate: CertificateResponse;
}

const CertificateCard: React.FC<CertificateCardProps> = ({ certificate }) => {
  const { copyText, handleCopy } = useHandleCopy();
  const { handleDownload } = useHandleDownload();
  const { t, i18n } = useTranslation('certificate');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens

  return (
    <Card
      elevation={0}
      className={classes.cards}
      sx={{
        border: `2px solid ${
          certificate.certificateType === 'FUNDED' ? yellow[800] : '#e73d44'
        }`,
        width: isSmallScreen ? '100%' : 'auto', // Adjust card width based on screen size
      }}
    >
      <CardContent>
        <Stack gap={2}>
          <Typography
            variant="button"
            className={classes.title}
            sx={{
              backgroundColor:
                certificate.certificateType === 'PAYOUT'
                  ? 'rgba(231, 61, 68, 0.3)'
                  : 'rgba(255, 224, 99, 0.3)',
              color:
                certificate.certificateType === 'PAYOUT'
                  ? '#E73D44'
                  : '#e1b811',
            }}
          >
            {certificate.certificateType}
          </Typography>
          <Box
            component="img"
            sx={{
              filter: !certificate.unlocked
                ? 'blur(18px) grayscale(0.8)'
                : 'none',
              width: isSmallScreen ? '100%' : 'auto', // Adjust image size for small screens
              height: 'auto',
            }}
            src={`data:image/png;base64,${certificate.image}`}
            alt={certificate.certificateType}
          />
          <Typography
            variant="caption"
            component="p"
            color="InactiveCaptionText"
            fontWeight="bold"
          >
            {certificate.unlocked &&
              t('OBTAINED_DATE', {
                date: new Date(certificate.obtainedDate).toLocaleDateString(
                  i18n.language,
                  {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  },
                ),
              })}
          </Typography>
        </Stack>
      </CardContent>
      {certificate.unlocked && (
        <CardActions>
          <Button
            variant="text"
            startIcon={<DownloadIcon />}
            onClick={() =>
              handleDownload(certificate.image, certificate.certificateType)
            }
          >
            {t('DOWNLOAD_BUTTON')}
          </Button>
          <Button
            variant="text"
            startIcon={<ContentCopyIcon />}
            onClick={() => handleCopy(certificate.id, certificate.image)}
          >
            {copyText[certificate.id] || t('COPY_BUTTON')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default CertificateCard;
