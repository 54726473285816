// import axiosInstance from '../../../../../utils/axios';

// import { IGetChallengesService } from './interfaces';
// import { AccountBase } from './models';
// import { handleError } from './errors';

// class GetChallengesService implements IGetChallengesService {
//   async getChallenges(token: string): Promise<AccountBase[]> {
//     try {
//       const response = await axiosInstance.get<AccountBase[]>(
//         '/v2/challenges/client-area',
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json',
//           },
//         },
//       );
//       return response.data;
//     } catch (error) {
//       handleError(error);
//       throw error;
//     }
//   }
// }

// export default new GetChallengesService();

import axiosInstance from '../../../../../utils/axios';
import { AccountBase } from './models';

const getChallenges = async (token: string): Promise<AccountBase[]> => {
  try {
    const response = await axiosInstance.get<AccountBase[]>(
      '/v2/challenges/client-area',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};

export default getChallenges;
