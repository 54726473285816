import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Stepper from '../../components/Stepper/Stepper';
import classes from './_paymentStatusPage.module.scss';
import Button from '../../components/userInterface/Button/Button';
import Loader from '../../components/Loader/Loader';
import {
  retryPayment,
  retryPaymentAPS,
  retryPaymentVasu,
} from '../../services/shop/shop';
import OldModal from '../../components/OldModal/OldModal';
import PixelBuySuccess from '../../utils/pixel/PixelBuySuccess';

type PaymentStatusType =
  | 'AWAITING_PAYMENT'
  | 'AWAITING_MT5_ACCOUNT_CREATION'
  | 'ACCOUNT_CREATED_SUCCESSFULLY'
  | 'MT5_ACCOUNT_CREATION_FAILED'
  | 'SOLVED_BY_ADMIN'
  | 'FAILED'
  | 'FAILED_APS_1'
  | 'FAILED_APS_2'
  | 'CANCELLED'
  | 'UNKNOWN_STATUS'
  | 'PAYMENT_PENDING';

const PaymentStatusPage: React.FC = () => {
  const navigate = useNavigate();

  const [paymentStatus, setPaymentStatus] =
    useState<PaymentStatusType>('PAYMENT_PENDING');

  const [uuid, setUuid] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [ApsFailed, setApsFailed] = useState(false);
  const [Aps1Failed, setAps1Failed] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { paymentId } = useParams<{ paymentId: string }>();

  const steps = [
    {
      title: 'Waiting for Payment',
      text: 'Your payment is currently pending.',
      customContent: <Loader />,
    },
    {
      title: 'Creating Account',
      text: 'We are now creating your account.',
      customContent: <Loader />,
    },
    {
      title: 'Account Created',
      text: 'Your account has been successfully created. Thank you for using our platform! Check your email for login details for the website and MetaTrader. 🎉',
    },
  ];

  const paymentStatusToStepIndex: Record<PaymentStatusType, number> = {
    AWAITING_PAYMENT: 0,
    AWAITING_MT5_ACCOUNT_CREATION: 1,
    ACCOUNT_CREATED_SUCCESSFULLY: 2,
    MT5_ACCOUNT_CREATION_FAILED: -1,
    SOLVED_BY_ADMIN: -1,
    FAILED: -1,
    FAILED_APS_1: -1,
    FAILED_APS_2: -1,
    CANCELLED: -1,
    UNKNOWN_STATUS: -1,
    PAYMENT_PENDING: 0,
  };

  const [currentStep, setCurrentStep] = useState<number>(
    paymentStatusToStepIndex[paymentStatus],
  );

  const closeWithdrawModal = () => {
    // Logic to close the modal
  };

  const handleStepChange = (newStep: number) => {
    // Logic to handle step change
  };
  const location = window.location;

  useEffect(() => {
    const currentUrl = window.location.href;
    const pattern = /\/buy-challenge\/payment-status\/([a-f0-9-]{36})/;
    const match = currentUrl.match(pattern);

    if (match) {
      setUuid(match[1]);
    }
  }, []);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      if (
        paymentId &&
        (currentStep === 0 || currentStep === 1) &&
        document.visibilityState === 'visible'
      ) {
        try {
          const response = await axios.get(
            `https://live.raisemyfunds.co/api/shop/status/${paymentId}`,
          );
          const status = response.data as PaymentStatusType;
          setPaymentStatus(status);
          setCurrentStep(paymentStatusToStepIndex[status]);

          //! eeeee
          if (response.data === 'FAILED_APS_PAYMENT') {
            setApsFailed(true);
          }

          if (response.data === 'FAILED_APS_1') {
            setAps1Failed(true);
          }
        } catch (error) {
          setCurrentStep(-1); // Set to 'Error' state on exception
        }
      }
    };

    fetchPaymentStatus();
    const intervalId = setInterval(fetchPaymentStatus, 8000);

    return () => {
      clearInterval(intervalId);
    };
  }, [paymentId, currentStep]);

  const handleRetryPayment = async () => {
    if (uuid) {
      setLoading(true);
      try {
        const url = await retryPayment(uuid);
        window.location.href = url;
      } catch (error) {
        console.error('Erreur lors de handleRetryPayment:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRetryPaymentAPS = async () => {
    if (uuid) {
      setLoading(true);
      try {
        const url = await retryPaymentAPS(uuid);
        window.location.href = url;
      } catch (error) {
        console.error('Erreur lors de handleRetryPayment:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    setSelectedDate(dateValue);
    if (dateValue) {
      setError('');
      formatDate(dateValue);
    }
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    setFormattedDate(`${year}-${month}-${day}`);
  };

  const handleRetryPaymentVasu = async () => {
    if (!selectedDate) {
      setError('Please select a date of birth.');
    } else {
      if (uuid) {
        setLoading(true);
        try {
          const url = await retryPaymentVasu(uuid, selectedDate);
          window.location.href = url;
        } catch (error) {
          console.error('Erreur lors de handleRetryPaymentVasu:', error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      {currentStep === 2 && <PixelBuySuccess />}
      <OldModal
        title="Payment Process"
        isOpen={true}
        onClose={closeWithdrawModal}
        fullOpacity={true}
      >
        <Stepper
          steps={steps}
          currentStep={currentStep}
          onStepChange={handleStepChange}
          className={classes.stepper}
          showNextButton={false}
        >
          <div className={classes.stepContent}>
            {currentStep === -1 ? (
              <>
                <p>An error occurred</p>
                {error && (
                  <div style={{ color: 'red', fontSize: '14px' }}>{error}</div>
                )}
                {Aps1Failed ? (
                  <>
                    <input
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      style={{
                        padding: '8px',
                        fontSize: '16px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                      }}
                    />
                    <Button
                      variant="primary"
                      onClick={() => handleRetryPaymentVasu()}
                      disabled={loading}
                    >
                      {loading
                        ? 'Processing...'
                        : 'Retry with another provider'}
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => navigate('/buy-challenge')}
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : 'Return to our shop'}
                  </Button>
                )}
              </>
            ) : (
              <>
                <p>{steps[currentStep].text}</p>
                {currentStep === 0 && (
                  <Button
                    variant="primary"
                    onClick={() =>
                      (window.location.href =
                        'https://live.raisemyfunds.co/buy-challenge')
                    }
                  >
                    Return to our shop page
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    variant="primary"
                    onClick={() =>
                      (window.location.href = 'https://live.raisemyfunds.co/')
                    }
                  >
                    Go to Challenges
                  </Button>
                )}
              </>
            )}
          </div>
        </Stepper>
      </OldModal>
    </>
  );
};

export default PaymentStatusPage;
