import { useEffect, useState } from 'react';

import { useAuth } from '../../../../context/AuthContext/AuthContext';

import { CertificateResponse } from '../services/get/certificate/models';
import GetCertificatesService from '../services/get/certificate/getCertificate';

export const useCertificates = (anonymous: boolean) => {
  const { tokens } = useAuth();
  const [certificates, setCertificates] = useState<CertificateResponse[]>([]);

  useEffect(() => {
    const fetchCertificates = async () => {
      if (tokens) {
        try {
          const response = await GetCertificatesService.getCertificates(
            tokens.accessToken,
            anonymous,
          );
          setCertificates(response);
        } catch (error) {
          console.error('Failed to fetch certificates', error);
        }
      }
    };

    fetchCertificates();
  }, [tokens, anonymous]);

  return { certificates };
};
