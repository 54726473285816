import React, { useState } from 'react';
import {
  Button,
  Snackbar,
  Modal,
  Box,
  TextField,
  Stack,
  Typography,
} from '@mui/material';
import usePayout from '../ChallengeCard/hooks/usePayout';
import { useFundedAccountBaseData } from '../../context/FundedAccountBaseDataContext/FundedAccountBaseDataContext';
import { useTranslation } from 'react-i18next';

interface GetPayoutModalProps {
  open: boolean;
  onClose: () => void;
  accountUUID: number;
  accessToken: string;
}

const GetPayoutModal: React.FC<GetPayoutModalProps> = ({
  open,
  onClose,
  accountUUID,
  accessToken,
}) => {
  const { t } = useTranslation('area');

  const { fundedAccountBaseData } = useFundedAccountBaseData();
  const [amount, setAmount] = useState<number>(0);
  const {
    handlePayoutRequest,
    snackbarOpen,
    snackbarMessage,
    snackbarType,
    handleSnackbarClose,
    loading,
  } = usePayout();

  const handlePayout = async () => {
    await handlePayoutRequest(
      accountUUID,
      amount,
      accessToken,
      t(
        'CHALLENGE_CARD.MODALS.PAYOUT.SUCCESS_YOUR_PAYMENT_REQUEST_HAS_BEEN_SUCCESSFULLY_COMPLETED',
      ),
    );
    onClose(); // Fermer la modal après succès
  };

  if (!fundedAccountBaseData) {
    return null;
  }

  const { profit } = fundedAccountBaseData;

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxWidth: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component={'h2'}>
            {t(
              'CHALLENGE_CARD.MODALS.PAYOUT.TITLE_ARE_YOU_SURE_YOU_WANT_TO_REQUEST_A_PAYOUT',
            )}
          </Typography>
          <Typography
            variant="subtitle1"
            component={'h3'}
            color={'text.secondary'}
            mt={2}
            mb={4}
          >
            {t('CHALLENGE_CARD.MODALS.PAYOUT.TEXT_YOU_CAN_SCALE_UP')}
          </Typography>
          <Stack spacing={2}>
            <TextField
              label="Amount"
              type="number"
              fullWidth
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              disabled={loading}
              helperText={`Max: ${profit}`}
            />
            <Stack direction="row" gap={2} justifyContent={'flex-end'}>
              <Button onClick={onClose} disabled={loading} variant="outlined">
                {t('CHALLENGE_CARD.MODALS.PAYOUT.BUTTON_CANCEL')}
              </Button>
              <Button
                onClick={handlePayout}
                disabled={loading}
                variant="contained"
              >
                {t(
                  'CHALLENGE_CARD.MODALS.PAYOUT.BUTTON_YES_PROCEED_WITH_PAYOUT',
                )}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ContentProps={{
          sx: { backgroundColor: snackbarType === 'success' ? 'green' : 'red' },
        }}
      />
    </>
  );
};

export default GetPayoutModal;
