import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const version = '1.1';
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    returnEmptyString: false,
    nsSeparator: ':',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`,
    },
    ns: ['common', 'sidebar', 'login', 'forms', 'clientArea', 'components','area'],
    defaultNS: 'common',
    
  });

export default i18n;
