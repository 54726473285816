import React from 'react';

import { Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ProfileTabsProps {
  currentTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  isKYCCompleted: boolean | null;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({
  currentTab,
  handleTabChange,
  isKYCCompleted,
}) => {
  const { t } = useTranslation('profile');

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        centered
        variant="fullWidth"
      >
        <Tab label={t('TABS_NAME.PROFILE')} />
        <Tab
          label={`${t('TABS_NAME.KYC')} ${
            isKYCCompleted !== null ? (isKYCCompleted ? '✅' : '❌') : ''
          }`}
        />
      </Tabs>
    </Box>
  );
};

export default ProfileTabs;
