// AuthContext.tsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import { loginUser } from '../../pages/user/login/services/post/login/login';
import { decodeJWT } from '../../pages/user/login/services/utils/decodedJWT';
import { fetchProfile } from '../../pages/user/profil/services/get/header/getProfile'; // Import pour récupérer le profil
import { LoginResponse } from '../../pages/user/login/services/post/login/interfaces';
import { JWTPayload } from '../../pages/user/login/services/post/login/models';

interface AuthContextProps {
  tokens: LoginResponse | null;
  user: JWTPayload | null;
  profile: any | null;
  login: (email: string, password: string, remember: boolean) => Promise<void>;
  isTokensLoading: boolean;
  logout: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [tokens, setTokens] = useState<LoginResponse | null>(null);
  const [user, setUser] = useState<JWTPayload | null>(null);
  const [profile, setProfile] = useState<any | null>(null); // Ajout de l'état pour le profil
  const [isTokensLoading, setIsTokensLoading] = useState(true);

  // Vérifier les tokens et récupérer le profil au démarrage
  useEffect(() => {
    const storedAccessToken =
      localStorage.getItem('accessToken') ||
      sessionStorage.getItem('accessToken');
    const storedRefreshToken =
      localStorage.getItem('refreshToken') ||
      sessionStorage.getItem('refreshToken');

    if (storedAccessToken && storedRefreshToken) {
      const decoded = decodeJWT(storedAccessToken);

      if (decoded) {
        setTokens({
          accessToken: storedAccessToken,
          refreshToken: storedRefreshToken,
        });
        setUser(decoded);

        fetchProfile(storedAccessToken, decoded.userId)
          .then((profileData) => setProfile(profileData))
          .catch((error) =>
            console.error('Failed to fetch sidebar profile', error),
          );
      }
    }

    setIsTokensLoading(false);
  }, []);

  const login = async (email: string, password: string, remember: boolean) => {
    try {
      const { tokens, user } = await loginUser(email, password, remember);
      setTokens(tokens);
      setUser(user);

      const profileData = await fetchProfile(tokens.accessToken, user.userId);
      setProfile(profileData);

      // Stocker les tokens dans localStorage ou sessionStorage
      if (remember) {
        localStorage.setItem('accessToken', tokens.accessToken);
        localStorage.setItem('refreshToken', tokens.refreshToken);
      } else {
        sessionStorage.setItem('accessToken', tokens.accessToken);
        sessionStorage.setItem('refreshToken', tokens.refreshToken);
      }
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    setTokens(null);
    setUser(null);
    setProfile(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        tokens,
        profile,
        isTokensLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }
  return context;
};
