import React, { useState } from 'react';
import { FundedSideCardDTO } from '../../../services/get/fundedChallenges/models';
import { AccountBase } from '../../../services/get/challenges/models';
import { green, yellow } from '../../../../../themes/colors/colors';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import classes from '../card.module.scss';
import UpgradeButton from '../../UpgradeButton/UpgradeButton';
import GetPayoutModal from '../../GetPayoutModal/GetPayoutModal';
import ScaleUpModal from '../../ScaleUpModal/ScaleUpModal';
import CredentialsModal from '../../CredentialsModal/CredentialsModal';
import { useTranslation } from 'react-i18next';

interface ChallengeCardProps {
  onSelect: (accountLogin: string) => void;
  onResetDate: () => void; // Fonction de reset du selectedDate
  challenges: (AccountBase | FundedSideCardDTO)[];
  isFunded: boolean;
  selectedAccountLogin: string | null;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  onSelect,
  onResetDate, // Réception de la fonction de reset du selectedDate
  challenges,
  isFunded,
  selectedAccountLogin,
}) => {
  const { t } = useTranslation('area');

  const [openCredentials, setOpenCredentials] = useState<boolean>(false);
  const [openPayoutModal, setOpenPayoutModal] = useState<boolean>(false);
  const [openScaleUpModal, setOpenScaleUpModal] = useState<boolean>(false);
  const [visibleStates, setVisibleStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [currentSelectedLogin, setCurrentSelectedLogin] = useState<
    string | null
  >(null);

  const theme = useTheme();

  const handleSwitchChange = (login: string) => {
    setVisibleStates((prevStates) => ({
      ...prevStates,
      [login]: !prevStates[login],
    }));
  };

  const handleOpenCredentials = (login: string): void => {
    setCurrentSelectedLogin(login);
    setOpenCredentials(true);
  };

  const handleCloseCredentials = (): void => {
    setOpenCredentials(false);
  };

  const handleOpenPayoutModal = (login: string): void => {
    setCurrentSelectedLogin(login);
    setOpenPayoutModal(true);
  };

  const handleClosePayoutModal = (): void => {
    setOpenPayoutModal(false);
  };

  const handleOpenScaleUpModal = (login: string): void => {
    setCurrentSelectedLogin(login);
    setOpenScaleUpModal(true);
  };

  const handleCloseScaleUpModal = (): void => {
    setOpenScaleUpModal(false);
  };

  const handleCardClick = (login: string) => {
    onSelect(login); // Sélectionner le compte
    onResetDate(); // Réinitialiser la date sélectionnée
  };

  return (
    <>
      {challenges.map((challenge) => {
        const login = challenge.login;
        const balance = challenge.balance;

        const label = isFunded
          ? (challenge as FundedSideCardDTO).status === 'ACTIVE'
            ? t('CHALLENGE_CARD.CHIP_STATUS.ACTIVE')
            : t('CHALLENGE_CARD.CHIP_STATUS.INACTIVE')
          : (challenge as AccountBase).phase === 'PHASE_1'
          ? t('CHALLENGE_CARD.CHIP_STATUS.PHASE_1')
          : (challenge as AccountBase).phase === 'FAILED'
          ? t('CHALLENGE_CARD.CHIP_STATUS.FAILED')
          : t('CHALLENGE_CARD.CHIP_STATUS.PHASE_2');

        const chipBackgroundColor =
          isFunded && (challenge as FundedSideCardDTO).status === 'ACTIVE'
            ? green[300]
            : (challenge as AccountBase).phase === 'PHASE_1' ||
              (challenge as AccountBase).phase === 'PHASE_2'
            ? yellow[800]
            : theme.palette.primary.main;

        const isSelected = login === selectedAccountLogin;

        return (
          <Stack sx={{ position: 'relative' }} key={login}>
            <Card
              className={classes.card}
              elevation={0}
              sx={{
                filter: visibleStates[challenge.login] ? 'blur(5px)' : 'none',
                transition: 'filter 0.3s',
                pointerEvents: visibleStates[challenge.login] ? 'none' : 'auto',
                border: isSelected
                  ? '2px solid rgb(209, 72, 72)'
                  : '2px solid transparent',
              }}
              onClick={() => handleCardClick(login)} // Ajout de la fonction handleCardClick
            >
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignContent="flex-start"
                >
                  <Typography variant="h5" component="h3">
                    {t('CHALLENGE_CARD.TITLE_ACCOUNT')} {login}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    {!isFunded && (challenge as AccountBase).canUpgrade && (
                      <UpgradeButton
                        accountUUID={(challenge as AccountBase).challengeUUID}
                        phase={(challenge as AccountBase).phase}
                      />
                    )}
                    {isFunded && (challenge as FundedSideCardDTO).canPayout && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => setOpenPayoutModal(true)}
                      >
                        {t('CHALLENGE_CARD.BUTTONS.GET_PAYOUT')}
                      </Button>
                    )}
                    {isFunded &&
                      (challenge as FundedSideCardDTO).canScaleUp && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => setOpenScaleUpModal(true)}
                          sx={{
                            backgroundColor: green[300],
                            '&:hover': { backgroundColor: green[400] },
                          }}
                        >
                          {t('CHALLENGE_CARD.BUTTONS.SCALE_UP')}
                        </Button>
                      )}
                    <Chip
                      label={label}
                      sx={{
                        backgroundColor: chipBackgroundColor,
                        color: 'black',
                      }}
                    />
                  </Stack>
                </Stack>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  color="text.secondary"
                >
                  {t('CHALLENGE_CARD.TITLE_BALANCE')} ${balance}
                </Typography>
              </CardContent>
              <CardActions className={classes.actions}>
                <Button
                  onClick={() => handleOpenCredentials(login)}
                  variant="text"
                  size="large"
                  startIcon={<LayersIcon />}
                >
                  {t('CHALLENGE_CARD.BUTTONS.CREDENTIALS')}
                </Button>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={visibleStates[challenge.login] || false}
                        onClick={(event) => event.stopPropagation()}
                        onChange={() => handleSwitchChange(challenge.login)}
                      />
                    }
                    label={t('CHALLENGE_CARD.BUTTONS.CHECKBOX_VISIBLE')}
                  />
                </FormGroup>
              </CardActions>
            </Card>
            {visibleStates[challenge.login] && (
              <Stack className={classes.hidden}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSwitchChange(challenge.login)}
                >
                  {t('CHALLENGE_CARD.BUTTONS.SHOW_ACCOUNT')}
                </Button>
              </Stack>
            )}
          </Stack>
        );
      })}

      {currentSelectedLogin && (
        <CredentialsModal
          open={openCredentials}
          handleClose={handleCloseCredentials}
          selectedAccountLogin={currentSelectedLogin}
          isFunded={isFunded}
        />
      )}

      {isFunded && currentSelectedLogin && (
        <GetPayoutModal
          open={openPayoutModal}
          onClose={handleClosePayoutModal}
          accountUUID={Number(currentSelectedLogin)}
          accessToken="e"
        />
      )}

      {isFunded && currentSelectedLogin && (
        <ScaleUpModal
          open={openScaleUpModal}
          onClose={handleCloseScaleUpModal}
          accountUUID={currentSelectedLogin}
        />
      )}
    </>
  );
};

export default ChallengeCard;
