import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  EventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';

import Cookies from 'js-cookie';

import { Link, useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../context/AuthContext/AuthContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
  fetchShop,
  fetchShopConnected,
  applyPromoCode,
} from '../../services/shop/shop';
// import Card from '../../components/Cards/Card/Card';
import QuantitySelector from '../../components/QuantitySelector/QuantitySelector';
import Icon from '../../components/userInterface/Icon/Icon';
// import Button from '../../components/userInterface/Button/Button';
import Input from '../../components/userInterface/Input/Input';
import Select from '../../components/userInterface/Select/Select';
// import Checkbox from '../../components/userInterface/Checkbox/Checkbox';
import Loader from '../../components/Loader/Loader';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import axios from 'axios';

import classes from './_buyChallenge.module.scss';
import { cp } from 'fs';
import { MuiTelInput } from 'mui-tel-input';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PixelBuyChallenge from '../../utils/pixel/PixelBuyChallenge';
import { green } from '../../themes/colors/colors';

interface DiscountInfo {
  discountValue: number;
  discountPercentage: number;
  finalPrice: number;
}

interface QuantityItem {
  quantity: number;
  price: number;
  originalPrice: number;
}

interface Quantities {
  [key: string]: QuantityItem;
}

const BuyChallenge = () => {
  useDocumentTitle('New Challenge');

  const { t } = useTranslation(['buyChallenge', 'common']);
  const { isDarkMode } = useThemeContext();
  const { tokens, isTokensLoading } = useAuth();
  const { getCode } = require('country-list');

  const isPromotion = true;
  const prevTokenRef = useRef<string | null>(null);
  const location = window.location;
  const referredBy = new URLSearchParams(location.search).get('affiliate');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [challenges, setChallenges] = useState<{}[]>([]);
  const [multiplierFactors, setMultiplierFactors] = useState<any>();
  const [user, setUser] = useState<any>();
  const [discountInfo, setDiscountInfo] = useState<DiscountInfo | null>(null);
  const [quantities, setQuantities] = useState<Quantities>({});
  const [useVirtualWallet, setUseVirtualWallet] = useState(false);
  const [countryOptions, setCountryOptions] = useState<string[]>([]);
  const [phone, setPhoneValue] = useState('');
  const [phoneIsValid, setPhoneIsValid] = useState<boolean>(false);
  const [walletDeduction, setWalletDeduction] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | undefined>('');
  const [selectedPayment, setSelectedPayment] = useState<string | undefined>(
    t('billingInfoCard.creditCard'),
  );
  const [phoneError, setPhoneError] = useState<string>('');

  if (referredBy !== null) {
    Cookies.set('affiliate', referredBy, { expires: 30 });
    localStorage.setItem('affiliate', referredBy);
  }

  function isValidPhoneNumber(phoneNumber: string): boolean {
    const cleanedPhoneNumber = phoneNumber.replace(/[\s-.]/g, '');
    const regex = /^\+[1-9]\d{1,14}$/;

    if (cleanedPhoneNumber.length < 9) {
      return false;
    }

    return regex.test(cleanedPhoneNumber);
  }

  useEffect(() => {
    if (user?.phoneNumber) {
      setPhoneValue(user.phoneNumber);
      setPhoneIsValid(true);
    }
  }, [user?.phoneNumber]);

  const handlePhoneChange = (newValue: string) => {
    if (isValidPhoneNumber(newValue)) {
      setPhoneError('');
      setPhoneIsValid(true);
    } else {
      setPhoneError(t('billingInfoCard.PLEASE_ENTER_A_VALID_PHONE_NUMBER'));
      setPhoneIsValid(false);
    }

    setPhoneValue(newValue);
  };

  const preparePaymentData = (event: any) => {
    const items = Object.keys(quantities).map((key) => {
      return {
        balance: key.replace('USD', ''),
        quantity: quantities[key].quantity,
        price: quantities[key].price,
      };
    });

    // Récupérer les valeurs du formulaire
    const formData = new FormData(event.target);
    const firstName = formData.get('firstname');
    const lastName = formData.get('lastname');
    const email = formData.get('email');
    const country = selectedOption;
    const city = formData.get('city');
    const street = formData.get('street');
    const zipCode = formData.get('postalCode');
    return {
      items,
      email: email,
      promoCode: promoCode,
      phoneNumber: user?.phoneNumber || phone,
      firstname: firstName,
      lastname: lastName,
      country: country || user?.country || 'Albania',
      zipCode: zipCode,
      city: city,
      street: street,
      ipAddress: user?.ipAddress,
      referredBy: localStorage.getItem('affiliate') || Cookies.get('affiliate'),
      role: 'ROLE_USER',
      useVirtualWallet: useVirtualWallet,
    };
  };

  const updateStates = (data: any) => {
    setChallenges(data.challenges);
    setMultiplierFactors(data);
    setUser(data);
    const initialQuantities = data.challenges.reduce(
      (acc: any, challenge: any) => {
        acc[`${challenge.challengeBalance}USD`] = {
          quantity: 0,
          price: challenge.currentPrice,
          originalPrice:
            challenge.currentPrice < challenge.initialPrice
              ? challenge.currentPrice
              : challenge.initialPrice,
        };
        return acc;
      },
      {},
    );
    setQuantities(initialQuantities);
  };

  const handlePayment = async (event: any) => {
    event.preventDefault();

    const hasSelectedItems = Object.values(quantities).some(
      (item) => item.quantity > 0,
    );
    if (!hasSelectedItems) {
      toast.info(t('billingInfoCard.PLEASE_SELECT_AT_LEAST_ONE_ARTICLE'), {
        progressStyle: { backgroundColor: 'cornflowerblue' },
      });
      return;
    }

    if (!phoneIsValid) {
      toast.error(t('billingInfoCard.INVALID_PHONE_NUMBER'), {
        progressStyle: { backgroundColor: 'red' },
      });
      return;
    }
    // window.open("https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExOGVoczBha2wwZm1venIwdWY0OWFyeHM3bTJwNHlvZWRveXBkcGhmdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/L1QnWsvqGB7hrAJ5H3/giphy.gif");

    try {
      const paymentData = preparePaymentData(event);

      // L'URL varie selon le type de paiement sélectionné
      const url =
        selectedPayment === t('billingInfoCard.cryptoWallet')
          ? 'https://live.raisemyfunds.co/api/shop/initPayment'
          : 'https://live.raisemyfunds.co/api/shop/initFiatPayment';

      const response = await axios.post(url, paymentData);

      // Gérer les réponses spécifiques au paiement par crypto-wallet
      if (selectedPayment === t('billingInfoCard.cryptoWallet')) {
        if (response.data.paymentPage) {
          // Rediriger vers la page de paiement si nécessaire
          window.location.href = response.data.paymentPage;
          // navigate(`/buy-challenge/payment-status/${response.data.paymentId}`);
        } else if (response.data.message === 'Payment completed using wallet') {
          // Gérer le cas où le paiement a été effectué entièrement par le wallet
          navigate(`/buy-challenge/payment-status/${response.data.callbackId}`);
        }
      } else {
        if (response.status === 201) {
          // Paiement effectué par le wallet

          navigate(`/buy-challenge/payment-status/${response.data.callbackId}`);
        } else {
          // Redirection pour les paiements fiat
          window.location.href = response.data.url;
        }
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        toast.error(
          t('billingInfoCard.CANNOT_EXCEED_TOTAL_FUNDING_400K_FROM_CHALLENGES'),
          {
            progressStyle: { backgroundColor: 'red' },
          },
        );
      } else if (error.response.status === 409) {
        toast.error(t('billingInfoCard.THIS_PHONE_NUMBER_IS_ALREADY_USE'), {
          progressStyle: { backgroundColor: 'red' },
        });
      } else {
        console.error("Erreur lors de l'initiation du paiement: ", error);
      }
    }
  };

  const [promoCode, setPromoCode] = useState('');
  const [promoError, setPromoError] = useState('');

  const handlePromoCodeChange = (e: any) => {
    setPromoCode(e.target.value);
  };

  const handleApplyPromoCode = async () => {
    if (!promoCode) {
      setPromoError(t('billingInfoCard.enterPromoCodeError'));
      return;
    }

    try {
      const originalPrice = Object.values(quantities).reduce(
        (sum, { quantity, originalPrice }) => {
          const numQuantity = Number(quantity);
          const numPrice = Number(originalPrice);
          return (
            sum +
            (isNaN(numQuantity) || isNaN(numPrice) ? 0 : numQuantity * numPrice)
          );
        },
        0,
      );

      if (!tokens || !tokens.accessToken) {
        const response = await applyPromoCode(
          null,
          promoCode,
          originalPrice,
          referredBy,
        );
        setDiscountInfo(response);
      } else {
        const response = await applyPromoCode(
          tokens.accessToken,
          promoCode,
          originalPrice,
          null,
        );
        setDiscountInfo(response);
      }
      setPromoError('');
    } catch (error) {
      setPromoError(t('billingInfoCard.failedToApplyPromoCode'));
      setDiscountInfo(null);
    }
  };

  useEffect(() => {
    fetch('https://live.raisemyfunds.co/api/localisation/allowed-countries')
      .then((response: any) => response.json())
      .then((data) => {
        setCountryOptions(data);
      });
  }, []);

  useEffect(() => {
    if (!isTokensLoading) {
      (async () => {
        setIsLoading(true);
        try {
          let data;
          if (tokens) {
            prevTokenRef.current = tokens.accessToken;
            data = await fetchShopConnected(tokens.accessToken);
          } else {
            data = await fetchShop();
          }
          updateStates(data);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [tokens, isTokensLoading]);

  const handleQuantityChange = (productKey: any, newQuantity: any) => {
    setQuantities((prevQuantities: any) => ({
      ...prevQuantities,
      [productKey]: {
        ...prevQuantities[productKey],
        quantity: newQuantity,
      },
    }));
    setDiscountInfo(null);
  };

  const calculateSubtotal = useCallback(() => {
    return Object.values(quantities).reduce((sum, { quantity, price }) => {
      const numQuantity = Number(quantity);
      const numPrice = Number(price);
      return (
        sum +
        (isNaN(numQuantity) || isNaN(numPrice) ? 0 : numQuantity * numPrice)
      );
    }, 0);
  }, [quantities]); // Les dépendances de useCallback

  useEffect(() => {
    const calculateWalletDeduction = () => {
      const subtotal = calculateSubtotal();
      let finalPrice = discountInfo ? discountInfo.finalPrice : subtotal;

      return useVirtualWallet
        ? Math.min(finalPrice, user?.currentWallet || 0)
        : 0;
    };

    setWalletDeduction(calculateWalletDeduction());
  }, [useVirtualWallet, user?.currentWallet, calculateSubtotal]); // calculateSubtotal est maintenant une dépendance stable

  const calculateTotalCost = () => {
    const subtotal = calculateSubtotal();
    let finalPrice = discountInfo ? discountInfo.finalPrice : subtotal;

    if (useVirtualWallet) {
      finalPrice -= walletDeduction;
    }

    return finalPrice;
  };

  const paymentOptions =
    user?.country === 'Algeria' || selectedOption === 'Algeria'
      ? [t('billingInfoCard.cryptoWallet')]
      : [t('billingInfoCard.creditCard'), t('billingInfoCard.cryptoWallet')];

  const handleSelectPayment = (value: string) => {
    setSelectedPayment(value);
  };

  useEffect(() => {}, [selectedPayment]);

  const handleSelect = (value: string) => {
    setSelectedOption(value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseVirtualWallet(event.target.checked);
  };

  const handleButtonClick = () => {
    setUseVirtualWallet(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ' ' || e.keyCode === 32) {
      e.preventDefault();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PixelBuyChallenge />
      {!tokens ? (
        <nav className={classes.nav}>
          <div className={classes.mobileNav}>
            <Link to="https://raisemyfunds.co/">
              <img src={require('../../assets/images/squareLogo.png')} alt="" />
            </Link>
            <div className={classes.mobileButtons}>
              <Button variant="outlined" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate('/buy-challenge')}
              >
                Get Funded
              </Button>
            </div>
          </div>
          <div className={classes.wrapper}>
            <Link to="https://raisemyfunds.co/">
              <img src={require('../../assets/images/fullLogo.png')} alt="" />
            </Link>
            <ul>
              <li>
                <Link to={'https://raisemyfunds.co/the-challenge/'}>
                  The Challenge
                </Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/faq/'}>FAQ</Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/about-us/'}>About Us</Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/partner/'}>
                  Be a Partner
                </Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/contact-us/'}>
                  Contact Us
                </Link>
              </li>
            </ul>
            <div>
              <Button variant="outlined" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate('/buy-challenge')}
              >
                Get Funded
              </Button>
            </div>
          </div>
        </nav>
      ) : null}

      <div className={classes.mainContainer}>
        <div className={classes.challengeContainer}>
          {challenges.map((challenge: any, index: number) => (
            <Card
              elevation={0}
              key={index}
              className={classes.card}
              sx={{ padding: '16px' }}
            >
              <h2>{`${challenge?.challengeBalance.toLocaleString(
                'fr-FR',
              )} USD`}</h2>
              <table
                className={`${classes.table} ${
                  isDarkMode ? classes.dark : classes.light
                }`}
              >
                <thead className={classes.table__header}>
                  <tr>
                    <th></th>
                    <th>{t('challengesCards.step')} 1</th>
                    <th>{t('challengesCards.step')} 2</th>
                  </tr>
                </thead>
                <tbody className={classes.table__body}>
                  <tr>
                    <td>{t('challengesCards.profitTarget')}</td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.profitTargetPhase1
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.profitTargetPhase2
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('challengesCards.maxDailyLoss')}</td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxDailyLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxDailyLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('challengesCards.maxLifetimeLoss')}</td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxLifetimeLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                    <td>
                      {`${(
                        challenge?.challengeBalance *
                        multiplierFactors?.maxLifetimeLoss
                      ).toLocaleString('fr-FR')}$`}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={classes.footer}>
                <QuantitySelector
                  initialQuantity={
                    quantities[`${challenge.challengeBalance}USD`]?.quantity ||
                    0
                  }
                  onQuantityChange={(newQuantity) =>
                    handleQuantityChange(
                      `${challenge.challengeBalance}USD`,
                      newQuantity,
                    )
                  }
                />
                <div className={classes.prices}>
                  {challenge?.currentPrice < challenge?.initialPrice ? (
                    <div
                      className={`${classes.price} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      {challenge?.currentPrice}€
                    </div>
                  ) : null}
                  <div
                    className={`${
                      challenge?.currentPrice < challenge?.initialPrice
                        ? classes.strikePrice
                        : classes.price
                    } ${isDarkMode ? classes.dark : classes.light}`}
                  >
                    {`${challenge?.initialPrice} €`}
                  </div>
                </div>
              </div>
            </Card>
          ))}
          <Card
            elevation={0}
            sx={{ padding: '16px' }}
            className={classes.infoCard}
          >
            <div className={classes.iconContainer}>
              <MonetizationOnIcon sx={{ fontSize: '42px' }} />
            </div>
            <div className={classes.content}>
              <h2>{t('useWalletCard.useWalletTitle')}</h2>
              <h3 className={classes.subtitle}>
                {tokens ? (
                  <>
                    {t('useWalletCard.useWalletSubtitle', {
                      money: user?.currentWallet,
                    })}
                  </>
                ) : (
                  ''
                )}
              </h3>
              <p>{t('useWalletCard.useWalletText')}</p>
            </div>

            <Button
              variant="contained"
              disabled={!tokens}
              onClick={handleButtonClick}
            >
              {t('useWalletCard.useWalletButton')}
            </Button>
          </Card>
        </div>
        <Card
          elevation={0}
          className={classes.sidebar}
          sx={{ padding: '16px' }}
        >
          <Typography variant="h5" component={'h5'} mb={4} fontWeight={'bold'}>
            {t('billingInfoCard.billingInfo')}
          </Typography>
          <form onSubmit={handlePayment}>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <TextField
                id="firstname"
                name="firstname"
                label={t('billingInfoCard.firstname')}
                defaultValue={user?.firstName}
                variant="outlined"
                fullWidth
                required
                sx={{ mr: 1 }}
              />
              <TextField
                id="lastname"
                name="lastname"
                label={t('billingInfoCard.lastname')}
                defaultValue={user?.lastName}
                variant="outlined"
                fullWidth
                required
                sx={{ ml: 1 }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <TextField
                id="country"
                name="country"
                select
                label={t('billingInfoCard.country')}
                value={selectedOption || user?.country || ''}
                onChange={(e) => setSelectedOption(e.target.value)}
                variant="outlined"
                fullWidth
                required
              >
                {countryOptions.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="city"
                name="city"
                label={t('billingInfoCard.city')}
                defaultValue={user?.city}
                variant="outlined"
                fullWidth
                required
                sx={{ ml: 1 }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <TextField
                id="street"
                name="street"
                label={t('billingInfoCard.street')}
                defaultValue={user?.street}
                variant="outlined"
                fullWidth
                required
                sx={{ mr: 1 }}
              />
              <TextField
                id="postalCode"
                name="postalCode"
                label={t('billingInfoCard.postalCode')}
                defaultValue={user?.postalCode}
                variant="outlined"
                fullWidth
                required
                sx={{ ml: 1 }}
              />
            </Box>
            <Box mb={3}>
              <TextField
                id="email"
                name="email"
                label={t('billingInfoCard.emailLabel')}
                defaultValue={user?.email}
                variant="outlined"
                fullWidth
                required
              />
            </Box>
            <Box mb={2}>
              <MuiTelInput
                id="phone"
                name="phone"
                label={t('billingInfoCard.PHONE_NUMBER_LABEL')}
                defaultCountry={user?.country ? getCode(user?.country) : null}
                value={phone}
                onChange={handlePhoneChange}
                error={!!phoneError}
                helperText={phoneError}
                fullWidth
              />
              <Divider sx={{ mt: 4, mb: 4 }} />
            </Box>

            {/* Payment Method Section */}
            <Box mb={3}>
              <TextField
                id="paymentMethod"
                name="paymentMethod"
                select
                label={t('billingInfoCard.paymentMethod')}
                value={selectedPayment}
                onChange={(e) => setSelectedPayment(e.target.value)}
                variant="outlined"
                fullWidth
              >
                {paymentOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {/* Coupon Code Section */}
            <Stack direction={'row'} spacing={2} mb={3}>
              <TextField
                id="couponCode"
                label={t('billingInfoCard.couponCode')}
                value={promoCode}
                onChange={handlePromoCodeChange}
                variant="outlined"
                fullWidth
                sx={{ flex: 2 }}
                error={!!promoError} // Indique si une erreur est présente
                helperText={promoError || ''} // Affiche le message d'erreur ou rien
              />

              <Button
                variant="contained"
                onClick={handleApplyPromoCode}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#f0f0f0', // Légère variation sur hover
                  },
                }}
              >
                {t('apply', { ns: 'common' })}
              </Button>
            </Stack>
            {/* {promoError && <Typography color="error">{promoError}</Typography>} */}

            {/* Platform Info and Price */}
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                {t('billingInfoCard.platform')}:
              </Typography>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                Metatrader 5
              </Typography>
            </Stack>
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                {t('billingInfoCard.price')}:
              </Typography>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                {calculateTotalCost()} €
              </Typography>
            </Stack>

            {/* Display Discount if Applicable */}
            {discountInfo && (
              <Typography
                variant="body1"
                component={'p'}
                color={'#1FCC92'}
                gutterBottom
              >
                -{discountInfo.discountValue}€ (
                {discountInfo.discountPercentage}% Off)
              </Typography>
            )}
            {useVirtualWallet && (
              <Typography
                variant="body1"
                component={'p'}
                color={'#1FCC92'}
                gutterBottom
              >
                -{walletDeduction}€ (from virtual wallet)
              </Typography>
            )}

            {/* Use Wallet Option */}
            {tokens && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useVirtualWallet}
                    onChange={(e) => setUseVirtualWallet(e.target.checked)}
                  />
                }
                label={t('useWalletCard.useWalletButton')}
              />
            )}

            {/* Submit Button */}
            <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
              {t('billingInfoCard.paymentButton')}
            </Button>
          </form>
        </Card>
      </div>

      <Card
        elevation={0}
        sx={{ padding: '16px' }}
        className={classes.conditions}
      >
        <h2>{t('challengeConditionsTable.challengeConditions')}</h2>

        <table
          className={`${classes.conditionTable} ${
            isDarkMode ? classes.dark : classes.light
          }`}
        >
          <thead className={classes.conditionTable__header}>
            <tr>
              <th></th>
              <th>{t('challengesCards.step')} 1</th>
              <th>{t('challengesCards.step')} 2</th>
              <th>{t('challengeConditionsTable.liveAccount')}</th>
            </tr>
          </thead>
          <tbody className={classes.conditionTable__body}>
            <tr>
              <td>{t('challengeConditionsTable.challengeDuration')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.profitTarget')}</td>
              <td>10%</td>
              <td>5% </td>
              <td>-</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.maxDailyLoss')}</td>
              <td>5% </td>
              <td>5% </td>
              <td>5%</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.maxLoss')}</td>
              <td>10% </td>
              <td>10% </td>
              <td>10%</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.refundableFee')}</td>
              <td>{t('challengeConditionsTable.challengePrice')}</td>
              <td>{t('challengeConditionsTable.free')}</td>
              <td>{t('challengeConditionsTable.refund')}</td>
            </tr>
          </tbody>
        </table>
        <Typography fontSize={18} mt={2}>
          {t('challengeConditionsTable.ON_THE_FIRST_SCALE_UP')}
        </Typography>
      </Card>
      {!tokens ? (
        <footer className={classes.mainFooter}>
          <div>
            <p>{t('footer.paragraphOne')}</p>
            <br />
            <p>{t('footer.paragraphTwo')}</p>
            <br />
            <p>{t('footer.paragraphThree')}</p>
            <br />
            <p>{t('footer.paragraphFour')}</p>
          </div>
          <hr />
          <p className={classes.copyright}>{t('footer.copyrightText')}</p>
        </footer>
      ) : null}
    </>
  );
};
export default BuyChallenge;
