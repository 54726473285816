import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { AccountBaseDataProvider } from '../../provider/AccountBaseDataProvider/AccountBaseDataProvider';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import getChallenges from '../../services/get/challenges/getChallenges';
import {
  ChallengeCard,
  Rules,
  StatsCard,
  Calendar,
  Summary,
  Chart,
  TradingHistory,
  AccordionCard,
  HeaderCards,
} from '../../index';
import { Stack, Typography } from '@mui/material';
import RadarIcon from '@mui/icons-material/Radar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import classes from '../styles/area.module.scss';
import { AccountBase } from '../../services/get/challenges/models';
import Error from '../../../../components/layouts/Error/Error';
import getTradeHistory from '../../services/get/tradeHistory/getTradeHistory';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import ChallengeSkeleton from '../../layouts/ChallengeSkeleton/ChallengeSkeleton';

const ClientArea = () => {
  const { isXL, isBetweenXSAndLG } = useBreakpoints();
  const { t } = useTranslation(['area', 'errors']);
  const { tokens } = useAuth();
  const [selectedAccountLogin, setSelectedAccountLogin] = useState<
    string | null
  >(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth() + 1,
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear(),
  );
  const [tradeHistory, setTradeHistory] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [challengeAccounts, setChallengeAccounts] = useState<AccountBase[]>([]);
  const [loading, setLoading] = useState(true);

  const tableRef = useRef<HTMLDivElement>(null);

  useDocumentTitle('Client Area');

  useEffect(() => {
    const fetchAccounts = async () => {
      if (tokens) {
        try {
          const challengeAccountsData = await getChallenges(tokens.accessToken);
          setChallengeAccounts(challengeAccountsData);
          if (challengeAccountsData.length > 0) {
            setSelectedAccountLogin(challengeAccountsData[0].login);
          }
        } catch (error) {
          console.error('Failed to fetch challenge accounts', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [tokens]);

  const fetchTradeHistory = async () => {
    if (selectedAccountLogin && tokens) {
      try {
        const params: any = { page: currentPage - 1, size: 10 };
        if (selectedDate) {
          params.date = selectedDate;
        } else {
          params.month = currentMonth;
          params.year = currentYear;
        }

        setTradeHistory([]);
        const tradeHistoryData = await getTradeHistory.getTradeHistory(
          selectedAccountLogin,
          tokens.accessToken,
          params,
        );
        setTradeHistory(tradeHistoryData.content);
        setTotalPages(tradeHistoryData.totalPages);
      } catch (error) {
        console.error('Failed to fetch trade history', error);
      }
    }
  };

  useEffect(() => {
    fetchTradeHistory();
  }, [
    selectedAccountLogin,
    selectedDate,
    currentMonth,
    currentYear,
    currentPage,
  ]);

  const handleSelectAccount = (accountLogin: string) => {
    setSelectedAccountLogin(accountLogin);
    resetSelectedDate(); // Réinitialise la date sélectionnée à chaque changement de compte
    // Réinitialiser le mois et l'année au mois et à l'année actuels
    setCurrentMonth(new Date().getMonth() + 1); // Mois actuel
    setCurrentYear(new Date().getFullYear()); // Année actuelle
  };

  const resetSelectedDate = () => {
    setSelectedDate(null);
  };

  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
  };

  const handleMonthYearChange = (month: number, year: number) => {
    setCurrentMonth(month);
    setCurrentYear(year);
    setSelectedDate(null); // Réinitialiser la date sélectionnée
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const resetPagination = () => {
    setCurrentPage(1); // Réinitialiser la pagination à la première page
  };

  return (
    <>
      <HeaderCards />
      {!loading && !challengeAccounts.length ? (
        <Error
          subtitle={t('YOU_DONT_HAVE_AN_ACCOUNT_YET', { ns: 'errors' })}
          documentTitle="Client Area - Raise My Funds"
        />
      ) : (
        <AccountBaseDataProvider selectedAccountLogin={selectedAccountLogin}>
          {isXL ? (
            <>
              <Stack className={classes.container} gap={4} direction="row">
                <Stack className={classes.challengesContainer} gap={2}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <RadarIcon />
                    <Typography variant="h5" component="h2" alignItems="center">
                      {t('TITLES.ACCOUNTS')}
                    </Typography>
                  </Stack>
                  {loading ? (
                    <ChallengeSkeleton />
                  ) : (
                    <Stack gap={2} className={classes.cardsContainer}>
                      <ChallengeCard
                        onSelect={handleSelectAccount}
                        onResetDate={resetSelectedDate}
                        challenges={challengeAccounts}
                        isFunded={false}
                        selectedAccountLogin={selectedAccountLogin}
                      />
                    </Stack>
                  )}
                </Stack>
                <Stack className={classes.dashboardContainer} gap={2}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <DashboardIcon />
                    <Typography variant="h5" component="h2" alignItems="center">
                      {t('TITLES.DASHBOARD')}
                    </Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Chart
                      elevation={0}
                      selectedAccountLogin={selectedAccountLogin}
                    />
                    <Rules
                      elevation={0}
                      selectedAccountLogin={selectedAccountLogin}
                    />
                  </Stack>

                  <StatsCard elevation={0} />
                </Stack>
              </Stack>
              <Stack
                mt={4}
                mb={4}
                direction="row"
                justifyContent="space-between"
                gap={2}
              >
                <Calendar
                  elevation={0}
                  selectedAccountLogin={selectedAccountLogin}
                  onSelectDate={handleSelectDate}
                  onMonthYearChange={handleMonthYearChange}
                  onResetPagination={resetPagination}
                  currentMonth={currentMonth} // Pass current month
                  currentYear={currentYear} // Pass current year
                />
                <Summary
                  selectedAccountLogin={selectedAccountLogin}
                  selectedDate={selectedDate}
                  onTradeHistoryFetched={setTradeHistory}
                  tableRef={tableRef}
                  inAccordion={false}
                />
              </Stack>
              <div ref={tableRef}>
                <TradingHistory
                  elevation={0}
                  selectedAccountLogin={selectedAccountLogin}
                  selectedDate={selectedDate}
                  tradeHistory={tradeHistory}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <Stack gap={2}>
              <Stack direction="row" alignItems="center" mt={6} gap={1}>
                <RadarIcon />
                <Typography variant="h5" component="h2" alignItems="center">
                  {t('TITLES.ACCOUNTS')}
                </Typography>
              </Stack>
              {loading ? (
                <ChallengeSkeleton />
              ) : (
                <AccordionCard
                  onSelect={handleSelectAccount}
                  onResetDate={resetSelectedDate}
                  challenges={challengeAccounts}
                  isFunded={false}
                >
                  <Stack gap={2}>
                    <Stack
                      direction={isBetweenXSAndLG ? 'column' : 'row'}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Chart
                        elevation={1}
                        selectedAccountLogin={selectedAccountLogin}
                      />
                      <Rules
                        elevation={1}
                        selectedAccountLogin={selectedAccountLogin}
                      />
                    </Stack>

                    <StatsCard elevation={1} />
                  </Stack>

                  <Stack mt={4}>
                    <Calendar
                      elevation={1}
                      selectedAccountLogin={selectedAccountLogin}
                      onSelectDate={handleSelectDate}
                      onMonthYearChange={handleMonthYearChange}
                      onResetPagination={resetPagination}
                      currentMonth={currentMonth} // Pass current month
                      currentYear={currentYear} // Pass current year
                    />
                    <Summary
                      selectedAccountLogin={selectedAccountLogin}
                      selectedDate={selectedDate}
                      onTradeHistoryFetched={setTradeHistory}
                      tableRef={tableRef}
                      inAccordion={true}
                    />
                  </Stack>

                  <div ref={tableRef}>
                    <TradingHistory
                      elevation={1}
                      selectedAccountLogin={selectedAccountLogin}
                      selectedDate={selectedDate}
                      tradeHistory={tradeHistory}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </AccordionCard>
              )}
            </Stack>
          )}
        </AccountBaseDataProvider>
      )}
    </>
  );
};

export default ClientArea;
