import React from 'react';

import { Avatar } from '@mui/material';

import NzdIcon from '../../../../../assets/images/calendar/NZD.png';
import AudIcon from '../../../../../assets/images/calendar/AUD.png';
import JpyIcon from '../../../../../assets/images/calendar/JPY.png';
import CnyIcon from '../../../../../assets/images/calendar/CNY.png';
import ChfIcon from '../../../../../assets/images/calendar/CHF.svg';
import EurIcon from '../../../../../assets/images/calendar/EUR.svg';
import GbpIcon from '../../../../../assets/images/calendar/GBP.svg';
import CadIcon from '../../../../../assets/images/calendar/CAD.png';
import UsdIcon from '../../../../../assets/images/calendar/USD.svg';
import GasIcon from '../../../../../assets/images/calendar/GAS.png';
import OilIcon from '../../../../../assets/images/calendar/OIL.png';
import DefaultIcon from '../../../../../assets/images/calendar/default.png';

interface InstrumentIconProps {
  instrumentName: string;
}

const InstrumentIcon: React.FC<InstrumentIconProps> = ({ instrumentName }) => {
  let imageUrl;

  switch (instrumentName) {
    case 'NZD':
      imageUrl = NzdIcon;
      break;
    case 'AUD':
      imageUrl = AudIcon;
      break;
    case 'JPY':
      imageUrl = JpyIcon;
      break;
    case 'CNY':
      imageUrl = CnyIcon;
      break;
    case 'CHF':
      imageUrl = ChfIcon;
      break;
    case 'EUR':
      imageUrl = EurIcon;
      break;
    case 'GBP':
      imageUrl = GbpIcon;
      break;
    case 'CAD':
      imageUrl = CadIcon;
      break;
    case 'USD':
      imageUrl = UsdIcon;
      break;
    case 'USGas':
      imageUrl = GasIcon;
      break;
    case 'oilWTI':
      imageUrl = OilIcon;
      break;
    default:
      imageUrl = DefaultIcon;
  }

  return <Avatar src={imageUrl} alt={instrumentName} />;
};

export default InstrumentIcon;
