import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Chip,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; // Utilise useNavigate pour naviguer
import { getIconComponent } from '../hooks/useGetIconComponent';
import { useTranslation } from 'react-i18next';

interface SidebarListProps {
  items: any[];
  open: boolean;
  isMobile: boolean;
  handleDrawerClose: () => void;
  isDarkMode: boolean;
}

const SidebarList: React.FC<SidebarListProps> = ({
  items,
  open,
  isMobile,
  handleDrawerClose,
  isDarkMode,
}) => {
  const location = useLocation();
  const navigate = useNavigate(); // Utilisation de useNavigate pour naviguer après fermeture
  const { t } = useTranslation(['sidebar']);

  const [loading, setLoading] = useState(false); // État pour gérer le chargement

  const handleLinkClick = (link: string) => {
    if (isMobile) {
      // Démarrer le chargement et fermer la Sidebar
      setLoading(true);
      handleDrawerClose();

      // Attendre que la Sidebar soit fermée avant de naviguer
      setTimeout(() => {
        setLoading(false); // Une fois que c'est prêt, réinitialise le chargement
        navigate(link); // Navigue après la fermeture
      }, 300); // Ajuste selon la durée de fermeture de la Sidebar
    } else {
      navigate(link); // En version desktop, navigue directement
    }
  };

  return (
    <List sx={{ margin: '10px 0px', marginTop: open ? '0px' : '80px' }}>
      {items.map((item) => {
        const IconComponent = getIconComponent(
          item.icon,
          isDarkMode,
          location.pathname,
        );

        return (
          <Tooltip
            title={t(item.name)}
            placement="right"
            key={item.name}
            disableHoverListener={open ? true : false}
          >
            <ListItem
              sx={{
                padding: '0px 16px',
                marginBottom: '10px',
                display: 'block',
                '& .MuiListItemButton-root': {
                  borderRadius: '5px',
                  ...(location.pathname === item.link && {
                    color: '#FFFFFF',
                  }),
                  backgroundColor:
                    location.pathname === item.link ? '#e73d44' : 'transparent',
                },
                '& .MuiListItemButton-root:hover': {
                  backgroundColor:
                    location.pathname === item.link
                      ? '#e73d44'
                      : 'rgba(231,61,68,.2)',
                },
              }}
            >
              <ListItemButton
                onClick={() => handleLinkClick(item.link)} // Utilise la fonction personnalisée
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  padding: '8px 12px',
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === item.link
                        ? '#FFFFFF'
                        : isDarkMode
                        ? '#FFFFFF'
                        : '#333333',
                    justifyContent: open ? 'initial' : 'center',
                    px: open ? 0 : 2.5,
                  }}
                >
                  {IconComponent}
                </ListItemIcon>
                <ListItemText
                  primary={t(item.name)}
                  sx={{ display: open ? 'block' : 'none' }}
                />
                {item.new && open && (
                  <Chip
                    label={'NEW'}
                    sx={{
                      backgroundColor:
                        location.pathname === item.link ? 'white' : '#e73d44',
                      color:
                        location.pathname === item.link ? '#333333' : '#FFFFFF',
                    }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          </Tooltip>
        );
      })}
    </List>
  );
};

export default SidebarList;
