import React from 'react';
import { Grid } from '@mui/material';
import CertificateCard from './CertificateCard';
import { CertificateResponse } from '../services/get/certificate/models';

interface CertificateTabsProps {
  certificates: CertificateResponse[];
}

const CertificateTabs: React.FC<CertificateTabsProps> = ({ certificates }) => {
  return (
    <Grid container spacing={3} justifyContent="center">
      {certificates.map((certificate) => (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={certificate.id}>
          <CertificateCard certificate={certificate} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CertificateTabs;
